<template>
    <div>
        <div class="h-full">
            <div class="flex flex-col h-[350px] w-full bg-[#333] bg-custom-gradient-2 p-4">
                <div class="flex flex-col mt-14">
                    <p class="font-semibold text-lg text-white">
                        {{ $store.state.data.card.name }}
                    </p>
                    <p class="font-bold text-[26px] text-white">{{ $store.state.data.card.balance }}</p>
                </div>

                <div class="flex gap-[10px] mt-[58px]">
                    <template v-for="item in $store.state.data.home.cards">
                        <template v-if="item.type.key == 'visa'">
                            <div class="relative w-[50px] h-[32px] bg-cover" :style="{'background-image': `url(${require('../../assets/tinkoff_new/img/card-bg-1.png')})`}">
                                <div class="absolute bottom-0 left-0 w-full flex justify-between items-center py-0.25 px-1">
                                    <p class="text-white font-medium text-[9px]">{{ item.number }}</p>
                                    <img src="../../assets/tinkoff_new/img/card-icon-1.svg" alt="card-icon-1" />
                                </div>
                            </div>
                        </template>
                        <template v-if="item.type.key == 'mastercard'">
                            <div class="relative w-[50px] h-[32px] bg-cover" :style="{'background-image': `url(${require('../../assets/tinkoff_new/img/card-bg-1.png')})`}">
                                <div class="absolute bottom-0 left-0 w-full flex justify-between items-center py-0.25 px-1">
                                    <p class="text-white font-medium text-[9px]">{{ item.number }}</p>
                                    <img src="../../assets/tinkoff_new/img/card-icon-2.svg" alt="card-icon-1" />
                                </div>
                            </div>
                        </template>
                        <template v-if="item.type.key == 'maestro'">
                            <div class="relative w-[50px] h-[32px] bg-cover" :style="{'background-image': `url(${require('../../assets/tinkoff_new/img/card-bg-1.png')})`}">
                                <div class="absolute bottom-0 left-0 w-full flex justify-between items-center py-0.25 px-1">
                                    <p class="text-white font-medium text-[9px]">{{ item.number }}</p>
                                    <img src="../../assets/tinkoff_new/img/card-icon-2.svg" alt="card-icon-1" />
                                </div>
                            </div>
                        </template>
                        <template v-if="item.type.key == 'mir'">
                            <div class="relative w-[50px] h-[32px] bg-cover" :style="{'background-image': `url(${require('../../assets/tinkoff_new/img/card-bg-2.png')})`}">
                                <div class="absolute bottom-0 left-0 w-full flex justify-between items-center py-0.25 px-1">
                                    <p class="text-white font-medium text-[9px]">{{ item.number }}</p>
                                    <img src="../../assets/tinkoff_new/img/card-icon-2.svg" alt="card-icon-1" />
                                </div>
                            </div>
                        </template>
                    </template>

                    <button class="flex justify-center items-center w-[50px] h-[33px] bg-[#FFFFFF12] rounded-[6px] backdrop-blur-8">
                        <img src="../../assets/tinkoff_new/img/plus.svg" alt="+" />
                    </button>
                </div>
                <div class="flex justify-between mt-10 p-4 rounded-[22px] bg-white">
                    <div class="flex flex-col items-center">
                        <div class="rounded-lg flex justify-center items-center">
                            <img src="../../assets/tinkoff_new/img/pay.svg" alt="pay" class="w-[24px] h-[24px]" />
                        </div>
                        <p class="mt-2 text-[#5295F9] text-[12px] font-semibold">Оплатить</p>
                    </div>

                    <div class="flex flex-col items-center">
                        <div class="rounded-lg flex justify-center items-center">
                            <img src="../../assets/tinkoff_new/img/topup.svg" alt="topup" class="w-[24px] h-[24px]" />
                        </div>
                        <p class="mt-2 text-[#5295F9] text-[12px] font-semibold">Пополнить</p>
                    </div>

                    <div class="flex flex-col items-center">
                        <div class="rounded-lg flex justify-center items-center">
                            <img src="../../assets/tinkoff_new/img/transfer.svg" alt="topup" class="w-[24px] h-[24px]" />
                        </div>
                        <p class="mt-2 text-[#5295F9] text-[12px] font-semibold">Перевести</p>
                    </div>
                </div>
            </div>

            <div class="flex justify-between p-4">
                <div @click="toTransactions" class="w-[180px] h-[120px] bg-white rounded-[15px] p-4 flex flex-col justify-between shadow-[0px_4px_54px_0px_rgba(0,0,0,0.1)]">
                    <div>
                        <p class="text-[#333333] font-bold text-[16px]">Все операции</p>
                        <p class="text-[#585858] text-[14px]">{{ $store.state.data.card.spending_month }}</p>
                        <p class="text-[#585858] text-[14px]">{{ $store.state.data.card.spending_month_value }}</p>
                    </div>
                    <!-- Progress bar -->
                    <div class="w-full h-[12px] rounded-full bg-gray-200 flex overflow-hidden">
                        <div class="h-full bg-[#DF507B]" style="width: 54%;"></div>
                        <div class="h-full bg-[#FE788B]" style="width: 13%;"></div>
                        <div class="h-full bg-[#A29AD1]" style="width: 11%;"></div>
                        <div class="h-full bg-[#52ACED]" style="width: 6%;"></div>
                        <div class="h-full bg-[#FF88AC]" style="width: 7%;"></div>
                        <div class="h-full bg-[#71C7E4]" style="width: 9%;"></div>
                    </div>
                </div>
                <div class="w-[180px] h-[120px] bg-white rounded-[15px] p-4 flex flex-col shadow-[0px_4px_54px_0px_rgba(0,0,0,0.1)]">
                    <!--Badge-->

                    <div class="flex items-baseline text-center gap-[6px] p-[6px] h-[26px] bg-custom-gradient rounded-[40px]" style="width: 50%;">
                        <img src="../../assets/tinkoff_new/img/star.svg" alt="crown" class="w-[11px] h-[8.5px]" />
                        <p class="font-semibold text-[12px] leading-none text-white">{{ $store.state.data.card.points }}</p>
                    </div>

                    <p class="font-medium text-sm text-[#585858] mt-2">Накоплено кэшбэка</p>
                    <div class="mt-2">
                        <p class="font-medium text-[12px] text-[#9398A1]">Зачислится</p>
                        <p class="font-medium text-[12px] text-[#9398A1]">{{ $store.state.data.card.сlean_up }}</p>
                    </div>
                </div>
            </div>

            <div class="bg-white rounded-[15px] p-4 mx-4 flex flex-col shadow-[0px_4px_54px_0px_rgba(0,0,0,0.1)]">
                <div class="flex justify-between">
                    <p class="font-bold text-lg text-black">Бонусы по {{ $store.state.data.card.name }}</p>
                    <p class="font-medium text-base text-[#5295F9]">Все</p>
                </div>
                <div class="flex overflow-x-scroll scroll-smooth gap-3 mt-2 bg-white mr-[-16px] scrollbar-hide">
                    <div class="bg-[#F6F7F8] rounded-[15px] w-[127px] h-[121px] p-4 flex flex-col justify-between flex-shrink-0">
                        <div class="flex flex-col">
                            <p class="font-semibold text-xs text-[#333333]">Кэшбэк</p>
                            <p class="font-semibold text-xs text-[#333333]">до 30%</p>
                        </div>
                        <div class="relative flex max-w-[100px] mt-4">
                            <img class="w-9 h-9 rounded-full shadow-lg" src="../../assets/tinkoff_new/img/av_1.png" alt="Avatar 1" />
                            <img class="w-9 h-9 rounded-full shadow-lg -ml-4" src="../../assets/tinkoff_new/img/av_2.png" alt="Avatar 2" />
                            <img class="w-9 h-9 rounded-full shadow-lg -ml-4" src="../../assets/tinkoff_new/img/av_3.png" alt="Avatar 3" />
                            <img class="w-9 h-9 rounded-full shadow-lg -ml-4" src="../../assets/tinkoff_new/img/av_4.png" alt="Avatar 4" />
                        </div>
                    </div>

                    <div class="bg-[#F6F7F8] rounded-[15px] w-[127px] h-[121px] p-4 flex flex-col justify-between flex-shrink-0">
                        <div class="flex flex-col">
                            <p class="font-semibold text-xs text-[#333333]">Повышенный</p>
                            <p class="font-semibold text-xs text-[#333333]">кэшбэк</p>
                            <p class="font-semibold text-xs text-[#333333]">в {{ $store.state.data.home.spending_month }}</p>
                        </div>
                        <div class="relative flex max-w-[100px]">
                            <img class="w-9 h-9 rounded-full shadow-lg" src="../../assets/tinkoff_new/img/av_5.png" alt="Avatar 1" />
                            <img class="w-9 h-9 rounded-full shadow-lg -ml-4" src="../../assets/tinkoff_new/img/av_6.png" alt="Avatar 2" />
                            <img class="w-9 h-9 rounded-full shadow-lg -ml-4" src="../../assets/tinkoff_new/img/av_7.png" alt="Avatar 3" />
                            <img class="w-9 h-9 rounded-full shadow-lg -ml-4" src="../../assets/tinkoff_new/img/av_8.png" alt="Avatar 4" />
                        </div>
                    </div>

                    <div class="bg-[#F6F7F8] rounded-[15px] w-[127px] h-[121px] p-4 flex flex-col justify-between flex-shrink-0">
                        <div class="flex flex-col">
                            <p class="font-semibold text-xs text-[#333333]">Кэшбэк</p>
                            <p class="font-semibold text-xs text-[#333333]">в разделе</p>
                            <p class="font-semibold text-xs text-[#333333]">Город</p>
                        </div>
                        <div class="relative flex max-w-[100px]">
                            <img class="w-9 h-9 rounded-full shadow-lg" src="../../assets/tinkoff_new/img/av_9.png" alt="Avatar 1" />
                            <img class="w-9 h-9 rounded-full shadow-lg -ml-4" src="../../assets/tinkoff_new/img/av_10.png" alt="Avatar 2" />
                            <img class="w-9 h-9 rounded-full shadow-lg -ml-4" src="../../assets/tinkoff_new/img/av_6.png" alt="Avatar 3" />
                            <img class="w-9 h-9 rounded-full shadow-lg -ml-4" src="../../assets/tinkoff_new/img/av_7.png" alt="Avatar 4" />
                        </div>
                    </div>
                </div>
            </div>

            <div class="bg-white rounded-[15px] p-4 m-4 flex shadow-[0px_4px_54px_0px_rgba(0,0,0,0.1)] justify-between items-center">
                <div class="flex items-center gap-2">
                    <img src="../../assets/tinkoff_new/img/heart.svg" alt="heart" class="w-9 h-9 object-fill" />
                    <p class="font-medium text-[15px] text-[#585858]">Расскажем, что есть на экране</p>
                </div>
                <img src="../../assets/tinkoff_new/img/close.png" alt="close" class="w-4 h-4" />
            </div>
        </div>

        <div class="sticky bottom-0 w-full bg-[#F8F8F8] px-8 py-4 flex justify-between items-center border-t border-[#E5E5E5]">
            <div class="flex flex-col items-center">
                <div class="relative rounded-lg flex justify-center items-center">
                    <img src="../../assets/tinkoff_new/img/home-active.svg" alt="home-active" class="w-[23px] h-[17px]" />

                    <div class="absolute -top-2 -right-2 bg-red-500 text-white text-[11px] font-normal w-4 h-4 flex justify-center items-center rounded-full" v-if="$store.state.data.home.notifications.home">
                        {{ $store.state.data.home.notifications.home }}
                    </div>
                </div>

                <p class="mt-2 text-[#5295F9] text-[9px] font-medium">Главная</p>
            </div>

            <div class="flex flex-col items-center">
                <div class="relative rounded-lg flex justify-center items-center">
                    <img src="../../assets/tinkoff_new/img/payments.svg" alt="payments" class="w-[23px] h-[17px]" />

                    <div class="absolute -top-2 -right-2 bg-red-500 text-white text-[11px] font-normal w-4 h-4 flex justify-center items-center rounded-full" v-if="$store.state.data.home.notifications.payments">
                        {{ $store.state.data.home.notifications.payments }}
                    </div>
                </div>

                <p class="mt-2 text-[#A3A8AE] text-[9px] font-medium">Платежи</p>
            </div>

            <div class="flex flex-col items-center">
                <div class="relative rounded-lg flex justify-center items-center">
                    <img src="../../assets/tinkoff_new/img/city.svg" alt="home" class="w-[23px] h-[17px]" />

                    <div class="absolute -top-2 -right-2 bg-red-500 text-white text-[11px] font-normal w-4 h-4 flex justify-center items-center rounded-full" v-if="$store.state.data.home.notifications.city">
                        {{ $store.state.data.home.notifications.city }}
                    </div>
                </div>

                <p class="mt-2 text-[#A3A8AE] text-[9px] font-medium">Город</p>
            </div>

            <div class="flex flex-col items-center">
                <div class="relative rounded-lg flex justify-center items-center">
                    <img src="../../assets/tinkoff_new/img/chat.svg" alt="chat" class="w-[23px] h-[17px]" />

                    <div class="absolute -top-2 -right-2 bg-red-500 text-white text-[11px] font-normal w-4 h-4 flex justify-center items-center rounded-full" v-if="$store.state.data.home.notifications.chat">
                        {{ $store.state.data.home.notifications.chat }}
                    </div>
                </div>

                <p class="mt-2 text-[#A3A8AE] text-[9px] font-medium">Чат</p>
            </div>
            <div class="flex flex-col items-center">
                <div class="relative rounded-lg flex justify-center items-center">
                    <img src="../../assets/tinkoff_new/img/more.svg" alt="more" class="w-[23px] h-[17px]" />
                </div>

                <p class="mt-2 text-[#A3A8AE] text-[9px] font-medium">Еще</p>
            </div>
        </div>
    </div>
</template>


<style>
  @import '../../assets/tinkoff/css/main.min.css';
</style>

<script>
    export default {
        head: {
            meta: [
                {
                    name: 'application-name', 
                    content: 'Тинькофф'
                },
                {
                    name: 'apple-mobile-web-app-title', 
                    content: 'Тинькофф'
                },
                {
                    name: 'author', 
                    content: 'Тинькофф'
                }
            ],
            link: [
                {
                    rel: 'apple-touch-icon',
                    href: require('../../assets/tinkoff/favicon/180x180.png')
                },
                {
                    rel: 'shortcut icon',
                    href: require('../../assets/tinkoff/favicon/32x32.png'),
                    sizes: '32x32',
                    type: 'image/png'
                },
            ]
        },
        data() {
            return {
                
            };
        },
        methods: {
            toTransactions() {
                this.$router.replace({
                   name: "TinkoffNewTransactions",
                   params: { 0: `/${this.$route.path}` },
                   replace: true,
                });
            },
        },
        beforeMount() {
            document.body.classList.remove('bg-[#333]')
            document.body.classList.add('h-screen')
            document.body.classList.add('bg-white')
        },
    }
</script>