<template>
    <div class="relative z-10 text-white text-center w-full">

        <div class="flex flex-col text-left p-4 gap-[15px] mt-8">
            <div class="flex justify-center items-center w-[44px] h-[44px] rounded-[14px] border-[0.5px] border-[#D9D9D966]">
                <img src="../../assets/sberbank/img/check-sber.png" alt="check-sber" />
            </div>
            <div class="flex flex-col space-y-0">
                <p class="font-semibold text-[28px] text-white">{{ $store.state.data.home.date }},</p>
                <p class="font-semibold text-[28px] text-white">{{ $store.state.data.home.name }}</p>
            </div>
        </div>
    </div>
</template>


<script>
    export default {
        head: {
            meta: [
                {
                    name: 'application-name', 
                    content: 'СберБанк'
                },
                {
                    name: 'apple-mobile-web-app-title', 
                    content: 'СберБанк'
                },
                {
                    name: 'author', 
                    content: 'СберБанк'
                }
            ],
            link: [
                {
                    rel: 'apple-touch-icon',
                    href: require('../../assets/sberbank/favicon/180x180.png')
                },
                {
                    rel: 'shortcut icon',
                    href: require('../../assets/sberbank/favicon/32x32.png'),
                    sizes: '32x32',
                    type: 'image/png'
                },
            ]
        },
        beforeMount() {
            document.body.classList.add('loader_background')
            document.body.classList.add('bg-cover')
            document.body.classList.add('bg-center')
        },
        mounted() {
            setTimeout(() => {
                this.$router.replace({
                    name: "SberbankAccount",
                    params: { 0: `/${this.$route.path}` },
                    replace: true,
                })
            }, 1500)
        }
    }
</script>