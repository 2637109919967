<template>
	<div class="font-dinpro bg-white text-black dark:bg-black dark:text-white h-full">

	    <div class="max-w-md mx-auto p-4" dark:bg-black bg-white>
	        <!-- Title -->
	        <div class="sticky top-0 z-10 bg-white dark:bg-black py-4">
	            <div class="flex justify-between items-center">
	                <button @click="toHome">
	                    <img src="../../assets/trustwallet/img/back-arrow-light.png" />
	                </button>
	                <div class="absolute left-1/2 transform -translate-x-1/2">
	                    <div class="flex flex-col items-center justify-center">
	                        <p class="text-lg font-semibold text-center">Tether</p>
	                        <div class="flex gap-2 items-center">
	                            <span class="text-sm text-gray-500 text-center">TRC20</span>
	                            <img class="theme-icon h-3" data-light="./../../assets/trustwallet/img/vertical-divider-light.png"
	                                data-dark="./../../assets/trustwallet/img/vertical-divider-dark.png"
	                                src="./../../assets/trustwallet/img/vertical-divider-light.png" />
	                            <span class="text-sm text-gray-500 text-center">{{ coin_data.blockhain }}</span>
	                        </div>
	                    </div>
	                </div>
	                <div class="flex gap-4">
	                    <button>
	                        <img src="../../assets/trustwallet/img/notification.png" />
	                    </button>
	                    <button>
	                        <img src="../../assets/trustwallet/img/info.png" />
	                    </button>
	                </div>
	            </div>
	        </div>

	        <!-- Balance -->
	        <div class="text-center mt-6">
	            <div class="flex justify-center items-center space-x-2">
	                <img :src="`${$config.ApplicationPublicURL}/img/trustwallet/home/coins/${coin_data.image}`" alt="USDT" class="w-10 h-10">
	            </div>
	            <p class="text-3xl font-bold mt-2">{{ coin_data.balance }} {{ coin_data.ticker }}</p>
	            <p class="text-gray-500 dark:text-gray-400">≈ {{ coin_data.balance_usd }} $</p>
	        </div>


	        <!-- Actions -->
	        <div class="grid grid-cols-5 gap-2 mt-6">
	            <div class="flex flex-col items-center gap-1">
	                <div class="flex justify-center items-center bg-gray-100 dark:bg-[#242426] rounded-full w-12 h-12">
	                    <img class="theme-icon" data-light="./../../assets/trustwallet/img/arrow-top-light.png"
	                        data-dark="./../../assets/trustwallet/img/arrow-top-dark.png" src="./../../assets/trustwallet/img/arrow-top-light.png" />
	                </div>
	                <span class="font-bold text-[14px] leading-[17.53px] tracking-normal text-center">
	                    Send
	                </span>
	            </div>

	            <div class="flex flex-col items-center gap-1">
	                <div class="flex justify-center items-center bg-gray-100 dark:bg-[#242426] rounded-full w-12 h-12">
	                    <img class="theme-icon" data-light="./../../assets/trustwallet/img/arrow-down-light.png"
	                        data-dark="./../../assets/trustwallet/img/arrow-down-dark.png" src="./../../assets/trustwallet/img/arrow-down-light.png" />
	                </div>
	                <span class="font-bold text-[14px] leading-[17.53px] tracking-normal text-center">
	                    Receive
	                </span>
	            </div>

	            <div class="flex flex-col items-center gap-1">
	                <div class="flex justify-center items-center bg-gray-100 dark:bg-[#242426] rounded-full w-12 h-12">
	                    <img class="theme-icon" data-light="./../../assets/trustwallet/img/card-light.png" data-dark="./../../assets/trustwallet/img/card-dark.png"
	                        src="./../../assets/trustwallet/img/card-light.png" />
	                </div>
	                <span class="font-bold text-[14px] leading-[17.53px] tracking-normal text-center">
	                    Buy
	                </span>
	            </div>

	            <div class="flex flex-col items-center gap-1">
	                <div class="flex justify-center items-center bg-gray-100 dark:bg-[#242426] rounded-full w-12 h-12">
	                    <img class="theme-icon" data-light="./../../assets/trustwallet/img/transfer-light.png"
	                        data-dark="./../../assets/trustwallet/img/transfer-dark.png" src="./../../assets/trustwallet/img/transfer-light.png" />
	                </div>
	                <span class="font-bold text-[14px] leading-[17.53px] tracking-normal text-center">
	                    Swap
	                </span>
	            </div>

	            <div class="flex flex-col items-center gap-1">
	                <div class="flex justify-center items-center bg-gray-100 dark:bg-[#242426] rounded-full w-12 h-12">
	                    <img class="theme-icon" data-light="./../../assets/trustwallet/img/bank-light.png" data-dark="./../../assets/trustwallet/img/bank-dark.png"
	                        src="./../../assets/trustwallet/img/bank-light.png" />
	                </div>
	                <span class="font-bold text-[14px] leading-[17.53px] tracking-normal text-center">
	                    Sell
	                </span>
	            </div>
	        </div>

	        <!-- Energy -->
	        <div class="flex justify-between gap-2 mt-6">
	            <div class="flex items-center justify-between w-full p-4 bg-gray-100 dark:bg-[#242426] rounded-2xl">
	                <div>
	                    <p class="text-gray-500 text-sm">Energy</p>
	                    <p class="text-2xl font-bold">0</p>
	                </div>
	                <div class="w-8 h-8 flex items-center justify-center bg-gray-100 rounded-full">
	                    <img src="./../../assets/trustwallet/img/energy-icon.png" alt="energy-icon">
	                </div>
	            </div>
	            <div class="flex items-center justify-between w-full p-4 bg-gray-100 dark:bg-[#242426] rounded-2xl ">
	                <div>
	                    <p class="text-gray-500 text-sm">Energy</p>
	                    <p class="text-2xl font-bold">0</p>
	                </div>
	                <div class="w-8 h-8 flex items-center justify-center bg-gray-100 rounded-full">
	                    <img src="./../../assets/trustwallet/img/energy-icon.png" alt="energy-icon">
	                </div>
	            </div>
	        </div>


	        <!--Helper-->
	        <div
	            class="mt-6 outline-dashed outline-gray-200 dark:outline-gray-700 p-4 rounded-lg flex items-center justify-center">
	            <p class="text-xs text-[#929AA5] dark:text-gray-400 mr-2">Can't find transaction?</p>
	            <a href="#" class="text-[#0500FF] dark:text-green-500 text-xs">Check explorer</a>
	        </div>


	        <!-- Transactions -->
	        <div class="mt-6 pb-24">
	            <div class="my-4" v-for="item in $store.state.data.transactions.transactions">
	                <p class="text-sm text-[#929AA5] font-bold mb-3">{{ item.date }}</p>
	                <!--Receive-->
	                <div class="flex justify-between items-center py-2" v-for="transaction in item.transactions" @click="toTransaction(transaction)" v-if="transaction.status">
	                    <div class="flex items-center gap-2">
	                        <div
	                            class="flex justify-center items-center bg-gray-100 dark:bg-[#242426] rounded-full w-12 h-12">
	                            <template v-if="transaction.type.key == 'receive'">
	                            	<img class="theme-icon" src="./../../assets/trustwallet/img/arrow-down-light.png" />
	                            </template>
	                            <template v-else-if="transaction.type.key == 'send'">
	                            	<img class="theme-icon" src="./../../assets/trustwallet/img/arrow-top-light.png" />
	                            </template>
	                        </div>
	                        <div class="flex flex-col gap-0">
	                            <p class="font-bold text-sm">{{ transaction.type.name }}</p>
	                            <p class="text-xs text-gray-500">{{ transaction.wallet }}</p>
	                        </div>
	                    </div>
	                    <p :class="{'text-green-500': transaction.type.key == 'receive', 'text-grey-700': transaction.type.key == 'send'}">
	                    	<template v-if="transaction.type.key == 'receive'">+</template>
	                    	<template v-else-if="transaction.type.key == 'send'">-</template>
	                    	{{ transaction.amount }} {{coin_data.ticker}}
	                	</p>
	                </div>
	            </div>

	        </div>

	        <!-- Curs info -->
	        <div class="mt-6 text-left fixed bottom-0 left-0 w-full bg-white dark:bg-black px-4 pb-9 shadow-lg">
	            <hr class="border-t border-gray-100 dark:border-gray-700 mb-4 -mx-4" />
	            <p class="text-sm font-[500] text-gray-500 dark:text-gray-400">Current {{coin_data.ticker}} price</p>
	            <div class="flex justify-between mt-2 items-center">
	                <div class="flex items-center gap-2">
	                    <p class="text-xl font-bold">{{coin_data.rate}} $ <span class="text-green-500 ml-3">{{coin_data.percent}}%</span></p>
	                    <img src="../../assets/trustwallet/img/chart-up.png" alt="chart-up" class="h-3 w-12 ml-3" />
	                </div>
	                <img src="../../assets/trustwallet/img/expand-icon.png" alt="expand-icon" class="h-1 w-2 mt-0.5" />
	            </div>
	        </div>
	    </div>

	</div>
</template>

<script>
	export default {
		head: {
            meta: [
                {
                    name: 'application-name', 
                    content: 'Trust'
                },
                {
                    name: 'apple-mobile-web-app-title', 
                    content: 'Trust'
                },
                {
                    name: 'author', 
                    content: 'Trust'
                }
            ],
            link: [
                {
                    rel: 'apple-touch-icon',
                    href: require('../../assets/trustwallet/favicon/180x180.png')
                },
                {
                    rel: 'shortcut icon',
                    href: require('../../assets/trustwallet/favicon/32x32.png'),
                    sizes: '32x32',
                    type: 'image/png'
                },
            ]
        },
        data () {
        	return {
        		coin: null,
        		coin_data: {
			        rate: null,
			        image: null,
			        ticker: null,
			        balance: null,
			        percent: null,
			        blockhain: null,
			        balance_usd: null,
        		}
        	}
    	},
        methods: {
        	toHome() {
			 	this.$router.replace({
                   name: 'TrustWalletHome',
                   params: { 0: `/${this.$route.path}` },
                   replace: true,
                })
			},
        	toTransaction(transaction) {
        		sessionStorage.setItem('transaction', JSON.stringify(transaction))

			 	this.$router.replace({
                   name: 'TrustWalletTransaction',
                   params: { 0: `/${this.$route.path}` },
                   replace: true,
                })
			}
        },
        mounted () {
        	this.coin = sessionStorage.getItem('coin')

        	this.coin_data = this.$store.state.data.home.coins.find(row => row.ticker == this.coin)
        }
	}
</script>