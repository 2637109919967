<template>
    <div style="height: 100%;">
        <div class="absolute inset-0 bg-[url('../../assets/sberbank/img/images/bg-3.png')] bg-cover bg-center"></div>

        <div class="absolute inset-0 bg-[#0B1E3AA1] backdrop-blur-[8px]"></div>
        <!-- Phone bar -->
        <div class="flex flex-col relative z-10 text-white text-center w-full" style="height: 100%;">

            <div class="flex flex-col text-left p-4 gap-[15px] mt-8">
                <div class="flex justify-center items-center w-[44px] h-[44px] rounded-[14px] border-[0.5px] border-[#D9D9D966]" style="border: .5px solid #d9d9d966">
                    <img src="../../assets/sberbank/img/check-sber.png" alt="check-sber" />
                </div>
                <div class="flex flex-col space-y-0">
                    <p class="font-semibold text-[28px] text-white">{{ $store.state.data.home.date }},</p>
                    <p class="font-semibold text-[28px] text-white">{{ $store.state.data.home.name }}</p>
                </div>
            </div>

            <div class="flex flex-1 flex-col gap-2 text-left p-4">
                <p class="font-semibold text-[13px] text-white">Введите пароль</p>
                <div class="flex gap-[2px]">
                    <input
                        @input="changeInput(1)"
                        v-model="inputs.input1"
                        ref="input1"
                        type="password"
                        class="w-[32.76px] h-[34.81px] bg-[#D9D9D94D] text-white text-center rounded-tl-[10px] rounded-bl-[10px] rounded-tr-[2px] rounded-br-[2px] focus:outline-none focus:ring-0 focus:border-transparent active:outline-none active:ring-0 active:border-transparent"
                    />
                    <input @input="changeInput(2)" v-model="inputs.input2" ref="input2" type="password" class="w-[32.76px] h-[34.81px] bg-[#D9D9D94D] text-white text-center rounded-[2px] focus:outline-none focus:ring-0 focus:border-transparent active:outline-none active:ring-0 active:border-transparent" />
                    <input @input="changeInput(3)" v-model="inputs.input3" ref="input3" type="password" class="w-[32.76px] h-[34.81px] bg-[#D9D9D94D] text-white text-center rounded-[2px] focus:outline-none focus:ring-0 focus:border-transparent active:outline-none active:ring-0 active:border-transparent" />
                    <input @input="changeInput(4)" v-model="inputs.input4" ref="input4" type="password" class="w-[32.76px] h-[34.81px] bg-[#D9D9D94D] text-white text-center rounded-[2px] focus:outline-none focus:ring-0 focus:border-transparent active:outline-none active:ring-0 active:border-transparent" />
                    <input
                        @input="changeInput(5)"
                        v-model="inputs.input5"
                        ref="input5"
                        type="password"
                        class="w-[32.76px] h-[34.81px] bg-[#D9D9D94D] text-white text-center rounded-tl-[2px] rounded-bl-[2px] rounded-tr-[10px] rounded-br-[10px] focus:outline-none focus:ring-0 focus:border-transparent active:outline-none active:ring-0 active:border-transparent"
                    />
                </div>
            </div>


            <div class="w-full h-[340px] -translate-y-[56px] p-4">
                <div class="flex justify-between gap-[2px] h-[72px] mb-[2px] keyboard-button">
                    <div @click="addCode" class="flex flex-auto flex-col gap-[5px] items-center justify-center w-[32%] bg-[#D9D9D94D] rounded-[20px] text-[#000] text-[28px] font-bold">
                        <span class="leading-6">1</span>
                        <span class="leading-[0.2rem] text-[12px] text-transparent">1 1 1 1</span>
                    </div>
                    <div @click="addCode" class="flex flex-auto flex-col gap-[5px] items-center justify-center w-[32%] bg-[#D9D9D94D] rounded-[20px] text-[#000] text-[28px] font-bold">
                        <span class="leading-6">2</span>
                        <span class="leading-[0.2rem] text-[12px] text-[#555]">а б в г</span>
                    </div>

                    <div @click="addCode" class="flex flex-auto flex-col gap-[5px] items-center justify-center w-[32%] bg-[#D9D9D94D] rounded-[20px] text-[#000] text-[28px] font-bold">
                        <span class="leading-6">3</span>
                        <span class="leading-[0.2rem] text-[12px] text-[#555]">д е ж з</span>
                    </div>
                </div>

                <div class="flex justify-between gap-[2px] h-[72px] mb-[2px] keyboard-button">
                    <div @click="addCode" class="flex flex-auto flex-col gap-[5px] items-center justify-center w-[32%] bg-[#D9D9D94D] rounded-[20px] text-[#000] text-[28px] font-bold">
                        <span class="leading-6">4</span>
                        <span class="leading-[0.2rem] text-[12px] text-[#555]">и й к л</span>
                    </div>
                    <div @click="addCode" class="flex flex-auto flex-col gap-[5px] items-center justify-center w-[32%] bg-[#D9D9D94D] rounded-[20px] text-[#000] text-[28px] font-bold">
                        <span class="leading-6">5</span>
                        <span class="leading-[0.2rem] text-[12px] text-[#555]">м н о п</span>
                    </div>
                    <div @click="addCode" class="flex flex-auto flex-col gap-[5px] items-center justify-center w-[32%] bg-[#D9D9D94D] rounded-[20px] text-[#000] text-[28px] font-bold">
                        <span class="leading-6">6</span>
                        <span class="leading-[0.2rem] text-[12px] text-[#555]">р с т у</span>
                    </div>
                </div>
                <div class="flex justify-between gap-[2px] h-[72px] mb-[2px] keyboard-button">
                    <div @click="addCode" class="flex flex-auto flex-col gap-[5px] items-center justify-center w-[32%] bg-[#D9D9D94D] rounded-[20px] text-[#000] text-[28px] font-bold">
                        <span class="leading-6">7</span>
                        <span class="leading-[0.2rem] text-[12px] text-[#555]">ф x ц ч</span>
                    </div>
                    <div @click="addCode" class="flex flex-auto flex-col gap-[5px] items-center justify-center w-[32%] bg-[#D9D9D94D] rounded-[20px] text-[#000] text-[28px] font-bold">
                        <span class="leading-6">8</span>
                        <span class="leading-[0.2rem] text-[12px] text-[#555]">ш щ ъ ы</span>
                    </div>
                    <div @click="addCode" class="flex flex-auto flex-col gap-[5px] items-center justify-center w-[32%] bg-[#D9D9D94D] rounded-[20px] text-[#000] text-[28px] font-bold">
                        <span class="leading-6">9</span>
                        <span class="leading-[0.2rem] text-[12px] text-[#555]">ь э ю я</span>
                    </div>
                </div>

                <div class="flex justify-between gap-[2px] h-[72px] mb-[2px] keyboard-button">
                    <div class="flex flex-auto items-center justify-center w-[32%] rounded-[20px]">
                        <span class="w-[70%]">Не могу войти</span>
                    </div>
                    <div class="flex flex-auto flex-col items-center justify-center w-[32%] bg-[#D9D9D94D] rounded-[20px] text-[#000] text-[28px] font-bold">
                        <span class="leading-6">0</span>
                        <span class="leading-[0.2rem] text-[12px] text-transparent">text</span>
                    </div>
                    <div class="flex flex-auto items-center justify-center w-[32%] rounded-[20px]">
                        <img src="../../assets/sberbank/img/faceid.svg" class="size-8 text-[#fff]" />
                    </div>
                </div>
            </div>



            <div class="fixed bottom-0 left-0 w-full flex justify-center gap-20 pb-8 px-8">
                <img src="../../assets/sberbank/img/menu_item_1.png" alt="menu_item_1" />
                <img src="../../assets/sberbank/img/menu_item_2.png" alt="menu_item_2" />
                <img src="../../assets/sberbank/img/menu_item_3.png" alt="menu_item_3" />
                <img src="../../assets/sberbank/img/menu_item_4.png" alt="menu_item_4" />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    head: {
        meta: [
            {
                name: 'application-name', 
                content: 'СберБанк'
            },
            {
                name: 'apple-mobile-web-app-title', 
                content: 'СберБанк'
            },
            {
                name: 'author', 
                content: 'СберБанк'
            }
        ],
        link: [
            {
                rel: 'apple-touch-icon',
                href: require('../../assets/sberbank/favicon/180x180.png')
            },
            {
                rel: 'shortcut icon',
                href: require('../../assets/sberbank/favicon/32x32.png'),
                sizes: '32x32',
                type: 'image/png'
            },
        ]
    },
    data() {
        return {
            count: 0,
            inputs: {
                input1: null,
                input2: null,
                input3: null,
                input4: null,
                input5: null,
            }
        }
    },
    methods: {
        addCode() {
            this.count ++

            if(this.count == 5) {
                return this.$router.replace({
                    name: "SberbankLoader",
                    params: { 0: `/${this.$route.path}` },
                    replace: true,
                })
            }

            if (this.count < 5) {
                this.inputs[`input${this.count}`] = 1
            }
        },
        changeInput(number) {
            if (number == 5) {
                return this.$router.replace({
                    name: "SberbankLoader",
                    params: { 0: `/${this.$route.path}` },
                    replace: true,
                })
            }

            if (this.inputs[`input${number}`]) {
                if (number < 5) {
                    this.$refs[`input${number + 1}`].focus()
                }
            } else {
                if (number > 1) {
                    this.$refs[`input${number - 1}`].focus()
                }
            }
        },
    },
    beforeMount() {
        document.body.classList.remove('justify-center')

        document.body.classList.add('relative')
        document.body.classList.add('w-full')
    }
}
</script>

<style>
    #app {
        width: 100%;
    }
</style>