<template>
    <div>
        <div class="bg-black rounded-b-[24px] pb-4">
            <div class="relative z-10 text-white text-center w-full"></div>
            <!-- Phone bar -->
            <div class="flex justify-between items-end sticky px-4 py-1">
            </div>
            <!-- Phone bar end -->

            <div class="flex gap-[10px] mt-6 ml-4 items-center">
                <div class="w-9 h-9 bg-[#FFFFFF1A] rounded-[11px] flex justify-center items-center">
                    <img src="../../assets/sberbank/img/profile.png" alt="profile" />
                </div>
                <div class="w-[232px] h-9 bg-[#FFFFFF1A] rounded-[11px] flex">
                    <img src="../../assets/sberbank/img/search.png" alt="search" class="m-2 w-[18px] h-[18px]" />
                    <input type="text" placeholder="Поиск" class="bg-transparent text-white w-full focus:outline-none" />
                </div>
                <img src="../../assets/sberbank/img/round-logo.png" alt="round-logo" class="w-[30px] h-[30px]" />
                <img src="../../assets/sberbank/img/notification.png" alt="notification" class="w-6 h-6" />
            </div>

            <div class="mt-6 mx-4 flex flex-col text-left">
                <p class="font-semibold text-xs font-sb">В кошельке</p>
                <div class="flex justify-between">
                    <div class="flex items-center">
                        <p class="font-semibold text-[23px] font-sb">
                            <span class="text-white">{{ balance }}</span>
                            <span class="text-[#FFFFFF99]">{{ balance2 }}</span>
                        </p>
                        <img src="../../assets/sberbank/img/right-arrow.png" class="w-[10px] h-[10px] ml-1" />
                    </div>
                    <div class="flex items-center gap-6">
                        <img src="../../assets/sberbank/img/visibility.png" alt="eye" class="w-[18px] h-[18px]" />
                        <img src="../../assets/sberbank/img/dots.png" alt="dots" class="w-4 h-4" />
                    </div>
                </div>
            </div>

            <div class="flex overflow-x-scroll scroll-smooth mt-2 ml-2 gap-[10px] overflow-y-hidden scrollbar-hide">
                <div class="flex flex-col gap-[10px] flex-shrink-0">
                    <div class="flex justify-center items-center bg-[#FFFFFF33] rounded-[14px] w-[54px] h-[62px]">
                        <img src="../../assets/sberbank/img/qr.png" alt="qr" class="w-6 h-6" />
                    </div>
                    <div class="flex justify-center items-center bg-[#FFFFFF33] rounded-[14px] w-[54px] h-[62px]">
                        <img src="../../assets/sberbank/img/guard.png" alt="guard" class="w-8 h-8" />
                    </div>
                </div>

                <div @click="toTransactions" class="flex flex-col bg-[#FFFFFF33] rounded-[14px] w-[127px] h-[132px] p-[14px] justify-between flex-shrink-0" v-for="item in $store.state.data.home.cards" v-if="item.status == 1">
                    <div class="flex flex-col text-left">
                        <img src="../../assets/sberbank/img/card.png" alt="card" class="w-[26px] h-[18px]" />
                        <p class="font-sb font-semibold text-[10px] leading-[9.1px] tracking-[0%] mt-1 text-[#FFFFFF99]">{{ item.number_card }}</p>
                    </div>
                    <div class="text-left">
                        <p class="font-semibold text-base text-white">{{ item.balance }}</p>
                        <p class="font-semibold text-[#FFFFFF99] text-xs">Счет •• {{ item.number_account }}</p>
                    </div>
                </div>

                <div class="flex flex-col bg-[#FFFFFF33] rounded-[14px] w-[127px] h-[132px] p-[14px] justify-between flex-shrink-0">
                    <div class="flex flex-col text-left">
                        <img src="../../assets/sberbank/img/c-logo.png" alt="card" class="w-[24px] h-[25px]" />
                        <p class="font-sb font-semibold text-[10px] leading-[9.1px] tracking-[0%] mt-1 text-[#FFFFFF99]">0231</p>
                    </div>
                    <div class="text-left">
                        <p class="font-semibold text-base text-white">Бонусы</p>
                        <p class="font-semibold text-white text-base">СберСпасибо</p>
                    </div>
                </div>
            </div>
        </div>

        <div class="flex mt-4 mx-2 bg-[#1E1E1E] bg-[url('../public/images/percent.png')] bg-no-repeat bg-[70%] rounded-[17px] p-4 h-[95px] flex-col relative">
            <p class="font-semibold text-[14px] text-white">Вклад «Лучший %»</p>
            <p class="font-semibold text-[12px] text-[#FFFFFF66]">До 22% годовых</p>
            <img src="../../assets/sberbank/img/close.png" alt="x" class="absolute top-4 right-4 w-[10px] h-[10px]" />
        </div>

        <div class="flex mt-4 mx-2 bg-[#1E1E1E] rounded-[17px] p-4 h-[220px] flex-col">
            <div class="flex justify-between">
                <p class="font-semibold text-[19px] text-white">Переводы на Сбер</p>
                <p class="font-semibold text-[14px] text-[#148F2B]">Все</p>
            </div>

            <div class="flex overflow-x-scroll scroll-smooth mt-2 gap-[16px] mr-[-16px] scrollbar-hide items-center">
                <div class="flex flex-col items-center text-center gap-[9px] flex-shrink-0 w-[54px] h-[91px]">
                    <img src="../../assets/sberbank/img/green-arrow.png" alt="->" class="w-[54px] h-[54px]" />
                    <div class="flex flex-col h-[30px] items-center justify-center">
                        <p class="font-semibold text-[11px] text-[#148F2B]">Новый</p>
                        <p class="font-semibold text-[11px] text-[#148F2B]">перевод</p>
                    </div>
                </div>

                <div class="flex flex-col items-center text-center gap-[9px] flex-shrink-0 w-[54px] h-[95px] overflow-visible pt-1" v-for="item of $store.state.data.home.transfers">
                    <div class="flex justify-center items-center w-[54px] h-[54px] bg-[#393939] rounded-[14px] relative overflow-visible">
                        <p class="font-semibold text-[19px] text-[#969696]">{{ item.firstname ? item.firstname.substring(0, 1) : null }} {{ item.lastname ? item.lastname.substring(0, 1) : null }}</p>
                        <img src="../../assets/sberbank/img/rounded-close.png" alt="->" class="absolute top-[-6px] right-[-6px] w-[22px] h-[22px] overflow-visible" />
                    </div>
                    <div class="flex flex-col h-[30px] items-center justify-center">
                        <p class="font-semibold text-[11px] text-white">{{ item.firstname }}</p>
                        <p class="font-semibold text-[11px] text-white">{{ item.lastname }}</p>
                    </div>
                </div>
            </div>
        </div>

        <div class="flex mt-4 mx-2 bg-[#1E1E1E] rounded-[17px] p-4 h-[220px] flex-col">
            <div class="flex justify-between">
                <p class="font-semibold text-[19px] text-white">Расходы в {{ $store.state.data.home.mounth }}</p>
                <p class="font-semibold text-[14px] text-[#148F2B]">Все</p>
            </div>
            <div class="flex overflow-x-scroll scroll-smooth mt-2 ml-2 gap-[10px] overflow-y-hidden mr-[-16px] scrollbar-hide">
                <div class="flex flex-col bg-[#292929] rounded-[14px] w-[132px] h-[144px] p-[14px] justify-between flex-shrink-0">
                    <div class="flex flex-col text-left">
                        <img src="../../assets/sberbank/img/green-round.png" alt="card" class="w-[32px] h-[32px]" />
                    </div>
                    <div class="text-left">
                        <p class="font-semibold text-base text-white">{{ $store.state.data.home.all_spends }}</p>
                        <p class="font-semibold text-[#FFFFFF99] text-[11px]">Все расходы</p>
                    </div>
                </div>

                <div class="flex flex-col bg-[#292929] rounded-[14px] w-[132px] h-[144px] p-[14px] justify-between flex-shrink-0">
                    <div class="flex flex-col text-left">
                        <img src="../../assets/sberbank/img/people.png" alt="card" class="w-[32px] h-[32px]" />
                    </div>
                    <div class="text-left">
                        <p class="font-semibold text-[#FFFFFF99] text-[11px]">Переводы людям</p>
                        <p class="font-semibold text-base text-white">{{ $store.state.data.home.people_transfers }}</p>
                    </div>
                </div>

                <div v-if="1==2" class="flex flex-col bg-[#292929] rounded-[14px] w-[132px] h-[144px] p-[14px] justify-between flex-shrink-0">
                    <div class="flex flex-col text-left">
                        <img src="../../assets/sberbank/img/video.png" alt="card" class="w-[32px] h-[32px]" />
                    </div>
                    <div class="text-left">
                        <p class="font-semibold text-[#FFFFFF99] text-[11px]">Кино и театр</p>
                        <p class="font-semibold text-base text-white">27 879 ₽</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        head: {
            meta: [
                {
                    name: 'application-name', 
                    content: 'СберБанк'
                },
                {
                    name: 'apple-mobile-web-app-title', 
                    content: 'СберБанк'
                },
                {
                    name: 'author', 
                    content: 'СберБанк'
                }
            ],
            link: [
                {
                    rel: 'apple-touch-icon',
                    href: require('../../assets/sberbank/favicon/180x180.png')
                },
                {
                    rel: 'shortcut icon',
                    href: require('../../assets/sberbank/favicon/32x32.png'),
                    sizes: '32x32',
                    type: 'image/png'
                },
            ]
        },
        data () {
            return {
                balance: 0,
                balance2: null
            }
        },
        methods: {
            toTransactions() {
                this.$router.replace({
                   name: "Sberbanktransactions",
                   params: { 0: `/${this.$route.path}` },
                   replace: true,
                });
            },
        },
        beforeMount() {

            var balance = this.$store.state.data.home.balance.split(',')

            this.balance = balance[0]
            if (balance.length > 1) {
                this.balance2 = ',' + balance[1]
            }

            document.body.classList.remove('loader_background')
            document.body.classList.remove('bg-cover')
            document.body.classList.remove('bg-center')

            document.body.classList.add('bg-[#181911]')
            document.body.classList.add('font-sb')
            document.body.classList.add('flex-col')
        },
    }
</script>