<template>
    <div :style="`background-image: url(${require('../../assets/sberbank/img/transactions-bg.png')})`" class="bg-cover bg-center rounded-b-[24px] h-full">
        <div class="relative z-10 text-white text-center w-full"></div>
        <!-- Phone bar -->
        <div class="flex justify-between items-end sticky px-4 py-§">
        </div>
        <!-- Phone bar end -->

        <div class="p-4 mt-4">
            <div class="flex justify-between w-[194px] h-[19px] items-center" @click="toAccount">
                <img src="../../assets/sberbank/img/left-arrow.png" alt="arrow-left" class="w-[11px]" />
                <img src="../../assets/sberbank/img/menu_item.png" alt="menu_item" />
            </div>

            <div class="mt-8 p-2 flex flex-col text-left gap-[21px]">
                <p class="font-semibold text-[12px] text-[#FFFFFF99]">Платежный счет •• {{ $store.state.data.home.cards[0].number_account }}</p>

                <div class="flex flex-col">
                    <div class="flex items-center">
                        <p class="font-semibold text-[23px] font-sb">
                            <span class="text-white">{{ balance }}</span>
                            <span class="text-[#FFFFFF99]">{{ balance2 }}</span>
                        </p>
                        <img src="../../assets/sberbank/img/right-arrow.png" class="w-[10px] h-[10px] ml-1" />
                    </div>

                    <p class="font-semibold text-[14px] text-white">Расходы в {{ $store.state.data.home.mounth }}: {{ $store.state.data.home.all_spends }}</p>
                </div>

                <div class="flex justify-between items-center mt-4">
                    <div class="flex items-center gap-2">
                        <div class="flex items-center justify-center bg-[#070D0B] w-[32px] h-[42px] rounded-xl">
                            <img src="../../assets/sberbank/img/plus.png" alt="plus" />
                        </div>

                        <div class="flex items-center justify-center bg-[#070D0B] w-[68px] h-[42px] rounded-xl gap-1">
                            <img src="../../assets/sberbank/img/mic-sber.png" alt="sber" />
                            <p class="font-semibold text-[13px] text-white">Pay</p>
                        </div>

                        <div class="flex items-center justify-center bg-[#070D0B] w-[185px] h-[42px] rounded-xl gap-1">
                            <img src="../../assets/sberbank/img/card.png" alt="sber" />
                            <p class="font-semibold text-[13px] text-white">{{ $store.state.data.transactions.card_name }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Info top end -->

        <div class="flex flex-col mt-[150px] p-2 gap-7">
            <div class="flex gap-2 items-center px-[10px]">
                <div class="flex items-center flex-col justify-between bg-[#1E1E1E] px-[10px] py-[16px] w-[111px] h-[82px] rounded-[20px]">
                    <img src="../../assets/sberbank/img/transfer.png" alt="transfers" />
                    <p class="font-semibold text-[12px] text-white">Перевести</p>
                </div>

                <div class="flex items-center flex-col justify-between bg-[#1E1E1E] px-[10px] py-[16px] w-[111px] h-[82px] rounded-[20px]">
                    <img src="../../assets/sberbank/img/uptop.png" alt="uptop" />
                    <p class="font-semibold text-[12px] text-white">Пополнить</p>
                </div>

                <div class="flex items-center flex-col justify-between bg-[#1E1E1E] px-[10px] py-[16px] w-[111px] h-[82px] rounded-[20px]">
                    <img src="../../assets/sberbank/img/setting.png" alt="setting" />
                    <p class="font-semibold text-[12px] text-white">Настроить</p>
                </div>
            </div>

            <div class="flex flex-col justify-between p-[15px] pb-[24px] bg-[#1E1E1E] rounded-[17px] gap-4">
                <div class="flex flex-col gap-1">
                    <p class="font-semibold text-[19px] text-white">Переводы на Сбер</p>
                    <p class="font-semibold text-[12px] text-[#787878]">Стоимость, бонусы и лимиты</p>
                </div>
                <div class="flex justify-between items-center">
                    <div class="flex gap-2 items-center">
                        <img src="../../assets/sberbank/img/crown.png" alt="crown" />
                        <p class="font-semibold text-[14px] text-white">Посмотреть</p>
                    </div>
                    <img src="../../assets/sberbank/img/right-arrow.png" alt="right-arrow" />
                </div>
            </div>

            <div class="flex flex-col justify-between p-[15px] pb-[24px] bg-[#1E1E1E] rounded-[17px] gap-4">
                <div class="flex flex-col gap-1">
                    <p class="font-semibold text-[19px] text-white">Реквизиты счета</p>
                </div>
                <div class="flex gap-2 items-start justify-start">
                    <img src="../../assets/sberbank/img/bill.png" alt="bill" />
                    <div class="flex flex-col flex-start justify-start">
                        <p class="font-semibold text-[14px] leading-[14px] text-white">Номер счета</p>
                        <p class="font-semibold text-[12px] text-[#888888]">{{ $store.state.data.transactions.number_account }}</p>
                    </div>
                </div>
            </div>
        </div>

        <!-- Transactions -->
        <div class="mt-6 p-4">
            <div class="my-4" v-for="item in $store.state.data.transactions.transactions">
                <p class="text-[19px] text-white font-semibold mb-3">{{ item.date }}</p>
                <!--Receive-->
                <div class="flex justify-between items-center py-2 relative" v-for="transaction in item.transactions" v-if="transaction.status == 1">
                    <div class="flex items-center gap-2">
                        <div class="flex justify-center items-center dark:bg-[#242426] rounded-full w-[32px] h-[32px]">
                            <img src="../../assets/sberbank/img/green-left-arrow.png" />
                        </div>
                        <!-- <img src="images/receive-button.png" alt="receive" class="h-9 w-auto" /> -->
                        <div class="flex flex-col gap-">
                            <p class="font-semibold text-[14px] text-white">{{ transaction.name }}</p>
                            <p class="text-xs text-[#888888] font-semibold">{{ transaction.type }}</p>
                        </div>
                    </div>
                    <p class="text-[#148F2B] font-semibold text-[16px]">{{ transaction.amount }}</p>
                    <div class="w-[320px] h-[1px] bg-[#242426] absolute left-10 bottom-0"></div>
                </div>
            </div>

        </div>
        <!-- Transactions end -->
    </div>
</template>


<script>
    export default {
        head: {
            meta: [
                {
                    name: 'application-name', 
                    content: 'СберБанк'
                },
                {
                    name: 'apple-mobile-web-app-title', 
                    content: 'СберБанк'
                },
                {
                    name: 'author', 
                    content: 'СберБанк'
                }
            ],
            link: [
                {
                    rel: 'apple-touch-icon',
                    href: require('../../assets/sberbank/favicon/180x180.png')
                },
                {
                    rel: 'shortcut icon',
                    href: require('../../assets/sberbank/favicon/32x32.png'),
                    sizes: '32x32',
                    type: 'image/png'
                },
            ]
        },
        data () {
            return {
                balance: 0,
                balance2: null
            }
        },
        methods: {
            toAccount() {
                this.$router.replace({
                   name: "SberbankAccount",
                   params: { 0: `/${this.$route.path}` },
                   replace: true,
                });
            },
        },
        beforeMount() {
            var balance = this.$store.state.data.home.balance.split(',')

            this.balance = balance[0]
            if (balance.length > 1) {
                this.balance2 = ',' + balance[1]
            }

            document.body.classList.remove('bg-[#181911]')

            document.body.classList.add('bg-black')
        },
    }
</script>