<template>
	<div class="font-dinpro bg-white text-black dark:bg-black dark:text-white h-full">
		<div class="max-w-md mx-auto p-4" dark:bg-black bg-white>
        	<!-- Title -->
	        <div class="sticky top-0 z-10 bg-white dark:bg-black py-4">
	            <div class="flex justify-between items-center">
	                <button>
	                    <img src="../../assets/trustwallet/img/settings.png" />
	                </button>
	                <p class="text-lg font-semibold text-center">Home</p>
	                <div class="flex gap-4">
	                    <button>
	                        <img src="../../assets/trustwallet/img/rounds.png" />
	                    </button>
	                </div>
	            </div>
	        </div>
	        <!-- Title end -->

	        <!-- Search -->

	        <div class="dark:bg-[#242426] bg-[#F4F4F7] rounded-[50px] px-3 py-2 min-h-8">
	            <div class="flex items-center gap-2 text-center">
	                <img src="../../assets/trustwallet/img/search.png" class="h-4 w-4" />
	                <p class="text-sm text-[#A2A2A4]">Search</p>
	            </div>
	        </div>
	        <!-- Search end -->

	        <!-- Balance -->
	        <div class="text-center mt-6">
	            <!-- Balance header -->

	            <div class="flex items-center justify-between">
	                <div class="flex items-center gap-2">
	                    <img src="../../assets/trustwallet/img/eye.png" alt="eye" class="h-5 w-5" />
	                    <p class="text-sm text-[#A2A2A4]">{{ $store.state.data.home.name }}</p>
	                    <img src="../../assets/trustwallet/img/bold-arrow-down.png" alt="arrow" class="h-2 w-2" />
	                </div>
	                <div class="flex items-center gap-2">
	                    <div class="w-7 h-7 bg-[#F4F4F7] rounded-lg flex items-center justify-center">
	                        <img src="../../assets/trustwallet/img/copy.png" alt="copy" class="h-3 w-3" />
	                    </div>
	                    <div class="w-7 h-7 bg-[#F4F4F7] rounded-lg flex items-center justify-center">
	                        <img src="../../assets/trustwallet/img/scan.png" alt="copy" class="h-3 w-3" />
	                    </div>
	                    <div class="w-7 h-7 bg-[#F4F4F7] rounded-lg flex items-center justify-center">
	                        <img src="../../assets/trustwallet/img/ring.png" alt="copy" class="h-3 w-3" />
	                    </div>
	                </div>
	            </div>

	            <p class="text-2xl font-bold mt-2 dark:text-[#43F983] text-black text-left">{{ $store.state.data.home.balance }} $</p>

	        </div>
	        <!-- Balance end -->

	        <!-- Actions -->
	        <div class="grid grid-cols-5 gap-2 mt-6">
	            <div class="flex flex-col items-center gap-1">
	                <div class="flex justify-center items-center bg-gray-100 dark:bg-[#242426] rounded-full w-12 h-12">
	                    <img class="theme-icon" data-light="../../assets/trustwallet/img/arrow-top-light.png"
	                        data-dark="../../assets/trustwallet/img/arrow-top-dark.png" src="../../assets/trustwallet/img/arrow-top-light.png" />
	                </div>
	                <span class="font-bold text-[14px] leading-[17.53px] tracking-normal text-center">
	                    Send
	                </span>
	            </div>

	            <div class="flex flex-col items-center gap-1">
	                <div class="flex justify-center items-center bg-gray-100 dark:bg-[#242426] rounded-full w-12 h-12">
	                    <img class="theme-icon" data-light="../../assets/trustwallet/img/arrow-down-light.png"
	                        data-dark="../../assets/trustwallet/img/arrow-down-dark.png" src="../../assets/trustwallet/img/arrow-down-light.png" />
	                </div>
	                <span class="font-bold text-[14px] leading-[17.53px] tracking-normal text-center">
	                    Receive
	                </span>
	            </div>

	            <div class="flex flex-col items-center gap-1">
	                <div class="flex justify-center items-center bg-gray-100 dark:bg-[#242426] rounded-full w-12 h-12">
	                    <img class="theme-icon" data-light="../../assets/trustwallet/img/card-light.png" data-dark="../../assets/trustwallet/img/card-dark.png"
	                        src="../../assets/trustwallet/img/card-light.png" />
	                </div>
	                <span class="font-bold text-[14px] leading-[17.53px] tracking-normal text-center">
	                    Buy
	                </span>
	            </div>

	            <div class="flex flex-col items-center gap-1">
	                <div class="flex justify-center items-center bg-gray-100 dark:bg-[#242426] rounded-full w-12 h-12">
	                    <img class="theme-icon" data-light="../../assets/trustwallet/img/bank-light.png" data-dark="../../assets/trustwallet/img/bank-dark.png"
	                        src="../../assets/trustwallet/img/bank-light.png" />
	                </div>
	                <span class="font-bold text-[14px] leading-[17.53px] tracking-normal text-center">
	                    Sell
	                </span>
	            </div>

	            <div class="flex flex-col items-center gap-1">
	                <div class="flex justify-center items-center bg-gray-100 dark:bg-[#242426] rounded-full w-12 h-12">
	                    <img src="../../assets/trustwallet/img/history.png" />
	                </div>
	                <span class="font-bold text-[14px] leading-[17.53px] tracking-normal text-center">
	                    History
	                </span>
	            </div>
	        </div>
	        <!-- Actions end -->

	        <!-- Tabs-->

	        <div class="flex justify-evenly mt-6">
	            <div>
	                <p class="text-lg text-[#202630]">Crypto</p>
	                <img src="../../assets/trustwallet/img/indicator.png" />
	            </div>
	            <p class="text-lg text-[#747A7A]">NFTs</p>
	        </div>


	        <!-- Coins -->
	        <div class="mt-6 pb-24">
	            <div class="flex justify-between items-center py-2" v-for="item in $store.state.data.home.coins" @click="toCoin(item.ticker)" v-if="item.status">
	                <div class="flex items-center gap-2">
	                    <img :src="`${$config.ApplicationPublicURL}/img/trustwallet/home/coins/${item.image}`" class="w-9 h-9" />
	                    <div class="flex flex-col gap-0">
	                        <div class="flex items-center gap-1">
	                            <p class="font-bold text-sm">{{ item.ticker }}</p>
	                            <div class="bg-[#F4F4F7] rounded-sm" style="padding: 1px 5px 1px 5px">
	                                <p class="text-xs text-center text-[#7C7F90]">{{ item.blockhain }}</p>
	                            </div>
	                        </div>
	                        <p class="text-xs text-gray-500">{{ item.rate }} $ <span :class="`text-[${item.percent.includes('+') ? '#2EBD85' : '#E33B54'}]`">{{ item.percent }}%</span></p>
	                    </div>
	                </div>
	                <div>
	                    <p class="text-[#202630] font-bold text-base" style="float: right">{{ item.balance }}</p>
	                    <p class="text-[#A9AAAE] text-xs">{{ item.balance_usd }} $</p>
	                </div>
	            </div>
	        </div>

	        <!-- Coins end -->

	        <!--Bottom menu-->

	        <div class="text-left fixed bottom-0 left-0 w-full bg-white dark:bg-black px-4 pb-9 shadow-lg">
	            <hr class="border-t border-gray-100 dark:border-gray-700 mb-4 -mx-4" />
	            <div class="flex justify-around items-center">
	                <div class="flex flex-col items-center gap-1">
	                    <img src="../../assets/trustwallet/img/home.png" />
	                    <p class="text-xs text-[#747A7A]">Home</p>
	                </div>
	                <div class="flex flex-col items-center gap-1">
	                    <img src="../../assets/trustwallet/img/switch.png" />
	                    <p class="text-xs text-[#747A7A]">Exchange</p>
	                </div>
	                <div class="flex flex-col items-center gap-1">
	                    <img src="../../assets/trustwallet/img/money.png" />
	                    <p class="text-xs text-[#747A7A]">Earn</p>
	                </div>
	                <div class="flex flex-col items-center gap-1">
	                    <img src="../../assets/trustwallet/img/donut.png" />
	                    <p class="text-xs text-[#747A7A]">More</p>
	                </div>
	            </div>
	        </div>

	    </div>
	</div>
</template>



<script>
	export default {
		head: {
            meta: [
                {
                    name: 'application-name', 
                    content: 'Trust'
                },
                {
                    name: 'apple-mobile-web-app-title', 
                    content: 'Trust'
                },
                {
                    name: 'author', 
                    content: 'Trust'
                }
            ],
            link: [
                {
                    rel: 'apple-touch-icon',
                    href: require('../../assets/trustwallet/favicon/180x180.png')
                },
                {
                    rel: 'shortcut icon',
                    href: require('../../assets/trustwallet/favicon/32x32.png'),
                    sizes: '32x32',
                    type: 'image/png'
                },
            ]
        },
        methods: {
        	toCoin(coin) {
        		sessionStorage.setItem('coin', coin)

			 	this.$router.replace({
                   name: 'TrustWalletCoin',
                   params: { 0: `/${this.$route.path}` },
                   replace: true,
                })
			}
        }
	}
</script>