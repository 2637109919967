<template>
    <div>
        <div class="max-w-md mx-auto p-4 mt-8 overflow-auto">
            <div class="flex items-center justify-between">
                <div class="flex space-x-2 items-center">
                    <div class="profile-block__icon">{{ $store.state.data.home.name.substring(0, 1) }}</div>
                    <div class="flex flex-col">
                        <div class="text-lg font-bold flex items-center">{{ $store.state.data.home.name }} <img src="../../assets/tinkoff_new/img/arrow-right.svg" alt=">" /></div>
                        <div class=""><img src="../../assets/tinkoff_new/img/pro-icon.svg" alt="pro" /></div>
                    </div>
                </div>
                <img src="../../assets/tinkoff_new/img/gift-icon.svg" alt="gift" />
            </div>

            <div class="mt-4 flex space-x-2 overflow-x-auto">
                <swiper
                    v-bind="swiper_options"
                    class="swiper">
                    <swiper-slide v-for="item in $store.state.data.home.images.banners" v-if="item.status == 1" class="random__slide">
                        <div class="relative w-[90px] h-[90px] rounded-[20px] overflow-hidden border border-[#5295F9] random-link">
                            <img :src="`${$config.ApplicationPublicURL}/img/tinkoff/home/banners/${item.image}`" alt="notifications-background-card" class="w-full h-full object-fill" />
                            <div class="absolute inset-0 flex items-end p-2">
                                <p class="text-white text-[10px] font-[500] pl-[2px]">{{ item.description }}</p>
                            </div>
                        </div>
                    </swiper-slide>
                </swiper>
            </div>

            <div class="flex justify-between mt-4">
                <!-- All operations -->
                <div class="w-[170px] h-[120px] bg-white rounded-[15px] p-4 flex flex-col justify-between shadow-[0px_4px_54px_0px_rgba(0,0,0,0.1)]">
                    <div>
                        <p class="text-[#333333] font-bold text-[16px]">Все операции</p>
                        <p class="text-[#585858] text-[14px]">Трат в {{ $store.state.data.home.spending_month }}</p>
                        <p class="text-[#585858] text-[14px]">{{ $store.state.data.home.spending_month_value }}</p>
                    </div>
                    <!-- Progress bar -->
                    <div class="w-full h-[12px] rounded-full bg-gray-200 flex overflow-hidden">
                        <div class="h-full bg-[#DF507B]" style="width: 54%;"></div>
                        <div class="h-full bg-[#FE788B]" style="width: 13%;"></div>
                        <div class="h-full bg-[#A29AD1]" style="width: 11%;"></div>
                        <div class="h-full bg-[#52ACED]" style="width: 6%;"></div>
                        <div class="h-full bg-[#FF88AC]" style="width: 7%;"></div>
                        <div class="h-full bg-[#71C7E4]" style="width: 9%;"></div>
                    </div>
                </div>

                <!-- Cashback -->
                <div class="w-[170px] h-[120px] bg-white rounded-[15px] p-4 flex flex-col justify-between relative shadow-[0px_4px_54px_0px_rgba(0,0,0,0.1)]">
                    <div>
                        <p class="text-[#333333] font-bold text-[16px] leading-none">Кэшбэк</p>
                        <p class="text-[#333333] font-bold text-[16px] leading-none">и бонусы</p>
                    </div>
                    <!-- Icons -->
                    <div class="flex space-x-1">
                        <div class="w-[32px] h-[32px] bg-blue-500 rounded-full flex items-center justify-center" v-for="item in $store.state.data.home.images.cashback" v-if="item.status == 1">
                            <img :src="`${$config.ApplicationPublicURL}/img/tinkoff/home/cashback/${item.image}`" alt="cashback" class="w-full h-full" />
                        </div>
                    </div>
                    <!-- Badge -->
                    <div class="absolute top-4 right-4 w-[23px] h-[23px] bg-red-500 text-white text-[15px] font-bold rounded-full flex items-center justify-center" v-if="1==2">
                        1
                    </div>
                </div>
            </div>

            <div class="mt-4 flex space-x-2 items-stretch">
                <div class="flex flex-col">
                    <div class="w-[83px] h-[44px]">
                        <img src="../../assets/tinkoff_new/img/sbp-with-bg.png" alt="sbp" class="w-full h-full object-fill rounded-[14px]" />
                    </div>
                    <p class="text-[10px] mt-2 text-[#585858] text-center">Перевести по телефону</p>
                </div>

                <div class="flex flex-col">
                    <div class="bg-[#F8F8F9] p-3 rounded-[14px] flex items-center justify-center w-[83px] h-[44px]">
                        <img src="../../assets/tinkoff_new/img/add-icon.png" alt="add" />
                    </div>
                    <p class="text-[10px] mt-2 text-[#585858] text-center">Пополнить блок</p>
                </div>

                <div class="flex flex-col">
                    <div class="bg-[#F8F8F9] p-3 rounded-[14px] flex items-center justify-center w-[83px] h-[44px]">
                        <img src="../../assets/tinkoff_new/img/switch.png" alt="switch" />
                    </div>
                    <p class="text-[10px] mt-2 text-[#585858] text-center">Между счетами</p>
                </div>
                <div class="flex flex-col">
                    <div class="bg-[#F8F8F9] p-3 rounded-[14px] flex items-center justify-center w-[83px] h-[44px]">
                        <img src="../../assets/tinkoff_new/img/qr-icon.png" alt="qr" />
                    </div>
                    <p class="text-[10px] mt-2 text-[#585858] text-center">Сканировать QR-код</p>
                </div>
            </div>

            <div class="mt-4 bg-white p-3 rounded-2xl shadow-[0px_4px_54px_0px_rgba(0,0,0,0.1)]">
                <div class="flex justify-between">
                    <div class="flex gap-4">
                        <div class="flex items-start justify-start">
                            <img src="../../assets/tinkoff_new/img/rub-icon.png" alt="rub-icon" class="w-[37px] h-[37px]" />
                        </div>
                        <div class="flex flex-col">
                            <p class="font-bold text-[#333333] text-[15px] leading-none" style="margin-bottom: 4px;">{{ $store.state.data.home.balance }}</p>
                            <p class="font-medium text-[#333333] text-[14px] leading-none">{{ $store.state.data.card.name }}</p>
                            <div class="flex gap-[6px] mt-4">
                                <template v-for="item in $store.state.data.home.cards">
                                    <template v-if="item.type.key == 'visa'">
                                        <div class="relative w-[50px] h-[32px] bg-cover" :style="{'background-image': `url(${require('../../assets/tinkoff_new/img/card-bg-1.png')})`}" @click="toCard">
                                            <div class="absolute bottom-0 left-0 w-full flex justify-between items-center py-0.25 px-1">
                                                <p class="text-white font-medium text-[9px]">{{ item.number }}</p>
                                                <img src="../../assets/tinkoff_new/img/card-icon-1.svg" alt="card-icon-1" />
                                            </div>
                                        </div>
                                    </template>
                                    <template v-if="item.type.key == 'mastercard'">
                                        <div class="relative w-[50px] h-[32px] bg-cover" :style="{'background-image': `url(${require('../../assets/tinkoff_new/img/card-bg-1.png')})`}" @click="toCard">
                                            <div class="absolute bottom-0 left-0 w-full flex justify-between items-center py-0.25 px-1">
                                                <p class="text-white font-medium text-[9px]">{{ item.number }}</p>
                                                <img src="../../assets/tinkoff_new/img/card-icon-1.svg" alt="card-icon-1" />
                                            </div>
                                        </div>
                                    </template>
                                    <template v-if="item.type.key == 'maestro'">
                                        <div class="relative w-[50px] h-[32px] bg-cover" :style="{'background-image': `url(${require('../../assets/tinkoff_new/img/card-bg-1.png')})`}" @click="toCard">
                                            <div class="absolute bottom-0 left-0 w-full flex justify-between items-center py-0.25 px-1">
                                                <p class="text-white font-medium text-[9px]">{{ item.number }}</p>
                                                <img src="../../assets/tinkoff_new/img/card-icon-1.svg" alt="card-icon-1" />
                                            </div>
                                        </div>
                                    </template>
                                    <template v-if="item.type.key == 'mir'">
                                        <div class="relative w-[50px] h-[32px] bg-cover" :style="{'background-image': `url(${require('../../assets/tinkoff_new/img/card-bg-2.png')})`}" @click="toCard">
                                            <div class="absolute bottom-0 left-0 w-full flex justify-between items-center py-0.25 px-1">
                                                <p class="text-white font-medium text-[9px]">{{ item.number }}</p>
                                                <img src="../../assets/tinkoff_new/img/card-icon-2.svg" alt="card-icon-1" />
                                            </div>
                                        </div>
                                    </template>
                                </template>
                            </div>
                        </div>
                    </div>

                    <div class="flex items-baseline text-center gap-[4px] p-[4px] h-[16px] bg-custom-gradient rounded-[40px]">
                        <img src="../../assets/tinkoff_new/img/star.svg" alt="crown" class="" />
                        <p class="font-semibold text-[10px] leading-none text-white">{{ $store.state.data.home.points }}</p>
                    </div>
                </div>
            </div>

            <img src="../../assets/tinkoff_new/img/howtouseservice.png" alt="howtouseservice" class="mt-4 w-full h-full object-fill" />

            <div class="mt-4 bg-white p-3 rounded-2xl shadow-[0px_4px_54px_0px_rgba(0,0,0,0.1)]">
                <div class="flex justify-between">
                    <div class="flex gap-4">
                        <div class="flex items-start justify-start">
                            <img src="../../assets/tinkoff_new/img/rub-icon.png" alt="rub-icon" class="w-[37px] h-[37px]" />
                        </div>
                        <div class="flex flex-col">
                            <p class="font-bold text-[#333333] text-[15px] leading-none" style="margin-bottom: 4px;">{{ $store.state.data.home.balance }}</p>
                            <p class="font-medium text-[#333333] text-[14px] leading-none">{{ $store.state.data.card.name }}</p>
                            <div class="flex gap-[6px] mt-4">
                                <template v-for="item in $store.state.data.home.cards">
                                    <template v-if="item.type.key == 'visa'">
                                        <div class="relative w-[50px] h-[32px] bg-cover" :style="{'background-image': `url(${require('../../assets/tinkoff_new/img/card-bg-1.png')})`}" @click="toCard">
                                            <div class="absolute bottom-0 left-0 w-full flex justify-between items-center py-0.25 px-1">
                                                <p class="text-white font-medium text-[9px]">{{ item.number }}</p>
                                                <img src="../../assets/tinkoff_new/img/card-icon-1.svg" alt="card-icon-1" />
                                            </div>
                                        </div>
                                    </template>
                                    <template v-if="item.type.key == 'mastercard'">
                                        <div class="relative w-[50px] h-[32px] bg-cover" :style="{'background-image': `url(${require('../../assets/tinkoff_new/img/card-bg-1.png')})`}" @click="toCard">
                                            <div class="absolute bottom-0 left-0 w-full flex justify-between items-center py-0.25 px-1">
                                                <p class="text-white font-medium text-[9px]">{{ item.number }}</p>
                                                <img src="../../assets/tinkoff_new/img/card-icon-2.svg" alt="card-icon-1" />
                                            </div>
                                        </div>
                                    </template>
                                    <template v-if="item.type.key == 'maestro'">
                                        <div class="relative w-[50px] h-[32px] bg-cover" :style="{'background-image': `url(${require('../../assets/tinkoff_new/img/card-bg-1.png')})`}" @click="toCard">
                                            <div class="absolute bottom-0 left-0 w-full flex justify-between items-center py-0.25 px-1">
                                                <p class="text-white font-medium text-[9px]">{{ item.number }}</p>
                                                <img src="../../assets/tinkoff_new/img/card-icon-2.svg" alt="card-icon-1" />
                                            </div>
                                        </div>
                                    </template>
                                    <template v-if="item.type.key == 'mir'">
                                        <div class="relative w-[50px] h-[32px] bg-cover" :style="{'background-image': `url(${require('../../assets/tinkoff_new/img/card-bg-2.png')})`}" @click="toCard">
                                            <div class="absolute bottom-0 left-0 w-full flex justify-between items-center py-0.25 px-1">
                                                <p class="text-white font-medium text-[9px]">{{ item.number }}</p>
                                                <img src="../../assets/tinkoff_new/img/card-icon-2.svg" alt="card-icon-1" />
                                            </div>
                                        </div>
                                    </template>
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="sticky bottom-0 w-full bg-[#F8F8F8] px-8 py-4 flex justify-between items-center border-t border-[#E5E5E5]">
            <div class="flex flex-col items-center">
                <div class="relative rounded-lg flex justify-center items-center">
                    <img src="../../assets/tinkoff_new/img/home-active.svg" alt="home-active" class="w-[23px] h-[17px]" />

                    <div class="absolute -top-2 -right-2 bg-red-500 text-white text-[11px] font-normal w-4 h-4 flex justify-center items-center rounded-full" v-if="$store.state.data.home.notifications.home">
                        {{ $store.state.data.home.notifications.home }}
                    </div>
                </div>

                <p class="mt-2 text-[#5295F9] text-[9px] font-medium">Главная</p>
            </div>

            <div class="flex flex-col items-center">
                <div class="relative rounded-lg flex justify-center items-center">
                    <img src="../../assets/tinkoff_new/img/payments.svg" alt="payments" class="w-[23px] h-[17px]" />

                    <div class="absolute -top-2 -right-2 bg-red-500 text-white text-[11px] font-normal w-4 h-4 flex justify-center items-center rounded-full" v-if="$store.state.data.home.notifications.payments">
                        {{ $store.state.data.home.notifications.payments }}
                    </div>
                </div>

                <p class="mt-2 text-[#A3A8AE] text-[9px] font-medium">Платежи</p>
            </div>

            <div class="flex flex-col items-center">
                <div class="relative rounded-lg flex justify-center items-center">
                    <img src="../../assets/tinkoff_new/img/city.svg" alt="home" class="w-[23px] h-[17px]" />

                    <div class="absolute -top-2 -right-2 bg-red-500 text-white text-[11px] font-normal w-4 h-4 flex justify-center items-center rounded-full" v-if="$store.state.data.home.notifications.city">
                        {{ $store.state.data.home.notifications.city }}
                    </div>
                </div>

                <p class="mt-2 text-[#A3A8AE] text-[9px] font-medium">Город</p>
            </div>

            <div class="flex flex-col items-center">
                <div class="relative rounded-lg flex justify-center items-center">
                    <img src="../../assets/tinkoff_new/img/chat.svg" alt="chat" class="w-[23px] h-[17px]" />

                    <div class="absolute -top-2 -right-2 bg-red-500 text-white text-[11px] font-normal w-4 h-4 flex justify-center items-center rounded-full" v-if="$store.state.data.home.notifications.chat">
                        {{ $store.state.data.home.notifications.chat }}
                    </div>
                </div>

                <p class="mt-2 text-[#A3A8AE] text-[9px] font-medium">Чат</p>
            </div>
            <div class="flex flex-col items-center">
                <div class="relative rounded-lg flex justify-center items-center">
                    <img src="../../assets/tinkoff_new/img/more.svg" alt="more" class="w-[23px] h-[17px]" />
                </div>

                <p class="mt-2 text-[#A3A8AE] text-[9px] font-medium">Еще</p>
            </div>
        </div>
    </div>
</template>


<style>
  @import '../../assets/tinkoff_new/css/main.min.css';
</style>

<script>
    import { Navigation, Pagination, Autoplay, Thumbs } from 'swiper'
    import { SwiperCore, Swiper, SwiperSlide } from 'swiper-vue2'

    SwiperCore.use([Navigation, Pagination, Autoplay, Thumbs])

    export default {
        head: {
            meta: [
                {
                    name: 'application-name', 
                    content: 'Тинькофф'
                },
                {
                    name: 'apple-mobile-web-app-title', 
                    content: 'Тинькофф'
                },
                {
                    name: 'author', 
                    content: 'Тинькофф'
                }
            ],
            link: [
                {
                    rel: 'apple-touch-icon',
                    href: require('../../assets/tinkoff_new/favicon/180x180.png')
                },
                {
                    rel: 'shortcut icon',
                    href: require('../../assets/tinkoff_new/favicon/32x32.png'),
                    sizes: '32x32',
                    type: 'image/png'
                },
            ]
        },
        components: {
            Swiper,
            SwiperSlide,
        },
        data() {
            return {
                swiper_options: {
                    slidesPerView: 'auto',
                    spaceBetween: 4
                }
            };
        },
        methods: {
            toCard() {
                this.$router.replace({
                   name: "TinkoffNewCard",
                   params: { 0: `/${this.$route.path}` },
                   replace: true,
                });
            },
        }
    }
</script>