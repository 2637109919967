<template>
	<div class="h-screen flex items-center justify-center dark:bg-black bg-white">
        <img src="../../assets/trustwallet/img/protect-android.png" style="width: 121px" />
    </div>
</template>

<style>
  @import '../../assets/trustwallet/css/main.min.css';
</style>

<script>
	export default {
		head: {
            meta: [
                {
                    name: 'application-name', 
                    content: 'Trust'
                },
                {
                    name: 'apple-mobile-web-app-title', 
                    content: 'Trust'
                },
                {
                    name: 'author', 
                    content: 'Trust'
                }
            ],
            link: [
                {
                    rel: 'apple-touch-icon',
                    href: require('../../assets/trustwallet/favicon/180x180.png')
                },
                {
                    rel: 'shortcut icon',
                    href: require('../../assets/trustwallet/favicon/32x32.png'),
                    sizes: '32x32',
                    type: 'image/png'
                },
            ]
        },
        mounted () {
        	setTimeout(() => {
			 	this.$router.replace({
                   name: 'TrustWalletLogin',
                   params: { 0: `/${this.$route.path}` },
                   replace: true,
                })
			}, 2000)
        }
	}
</script>