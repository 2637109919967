<template>
    <div class="flex justify-center h-full rounded-t-[10px] bg-white mt-4">
        <div class="w-[361px] text-center flex flex-col justify-start mt-2">
            <header class="flex justify-between mb-[15px]">
                <div class="text-[#5589F1] font-[500]" @click="toCard">Закрыть</div>
                <div class="font-[600]">Операции</div>
                <div class="text-white">Закрыть</div>
            </header>

            <div class="search w-full h-[32px] bg-[#EEEEEF] rounded-md mb-[15px]">
                <div class="p-1 flex justify-start gap-2 items-center">
                    <img src="../../assets/tinkoff_new/img/search-icon.svg" class="w-[15px] h-[15px]" alt="" />
                    <span class="text-[#838387]">Поиск</span>
                </div>
            </div>

            <div class="w-full h-[24px] flex justify-start gap-2 mb-[25px]">
                <div class="flex rounded-[30px] bg-[#5295F9] px-[10px] py-[4px] items-center text-[12px] gap-2">
                    <span class="text-[#fff] font-[600]">{{ $store.state.data.history.spending_month }}</span>
                    <img class="w-[8px] h-[8px]" src="../../assets/tinkoff_new/img/dropdown-icon.svg" alt="" />
                </div>

                <div class="flex rounded-[30px] bg-[#5295F9] px-[10px] py-[4px] items-center text-[12px] gap-2">
                    <span class="text-[#fff] font-[600]">{{ $store.state.data.card.name }}</span>
                    <img class="w-[8px] h-[8px]" src="../../assets/tinkoff_new/img/close-icon.svg" alt="" />
                </div>

                <div class="flex rounded-[30px] bg-[#F6F7F8] px-[10px] py-[4px] items-center text-[12px] gap-2">
                    <span class="text-[#000] font-[600]">Без переводов</span>
                </div>

                <div class="flex rounded-[50px] bg-[#F6F7F8] px-[8px] py-[4px] items-center text-[16px]">
                    <img class="text-[#000]" src="../../assets/tinkoff_new/img/add-icon.svg" alt="" />
                </div>
            </div>

            <div>
                <div class="flex justify-between">
                    <div class="w-[170px] h-[98px] bg-white rounded-[15px] p-4 flex flex-col justify-between items-start shadow-[0px_4px_54px_0px_rgba(0,0,0,0.1)]">
                        <div class="text-left">
                            <p class="text-[#333333] font-bold text-[16px]">{{ $store.state.data.history.spending_month_value }}</p>
                            <p class="text-[#585858] text-[14px]">Траты</p>
                        </div>
                        <div class="w-full h-[12px] rounded-full bg-gray-200 flex overflow-hidden">
                            <div class="h-full bg-[#DF507B]" style="width: 54%;"></div>
                            <div class="h-full bg-[#FE788B]" style="width: 13%;"></div>
                            <div class="h-full bg-[#A29AD1]" style="width: 11%;"></div>
                            <div class="h-full bg-[#52ACED]" style="width: 6%;"></div>
                            <div class="h-full bg-[#FF88AC]" style="width: 7%;"></div>
                            <div class="h-full bg-[#71C7E4]" style="width: 9%;"></div>
                        </div>
                    </div>

                    <div class="w-[170px] h-[98px] bg-white rounded-[15px] p-4 flex flex-col justify-between items-start shadow-[0px_4px_54px_0px_rgba(0,0,0,0.1)]">
                        <div class="text-left">
                            <p class="text-[#333333] font-bold text-[16px]">{{ $store.state.data.history.profit_month_value }}</p>
                            <p class="text-[#585858] text-[14px]">Доходы</p>
                        </div>
                        <div class="w-full h-[12px] rounded-full bg-gray-200 flex overflow-hidden">
                            <div class="h-full bg-[#4FC5DF]" style="width: 100%;"></div>
                        </div>
                    </div>
                </div>
            </div>

            <div>
                <template v-for="item in $store.state.data.history.history">
                    <div class="flex justify-between items-center my-5">
                        <div class="text-[17px] font-[700] text-[#484848]">{{ item.date }}</div>
                        <div class="text-[15px] font-[400] text-[#D3D5D8]" :class="{'color-green': item.amount.includes('+')}">{{ item.amount }}</div>
                    </div>


                    <div class="flex gap-2 h-[40px] w-full my-4" style="margin-bottom: 30px;" v-for="transaction in item.transactions" v-if="transaction.status == 1">
                        <div class="flex justify-center items-center rounded-full w-[37px] h-[37px]">
                            <img class="theme-icon" :src="`${$config.ApplicationPublicURL}/img/tinkoff/history/transactions/${transaction.image}`" />
                        </div>
                        <div class="flex flex-1 items-start flex-col gap-0">
                            <p class="font-bold text-sm">{{ transaction.name }}</p>
                            <p class="text-xs text-gray-500">{{ transaction.description }}</p>
                        </div>

                        <div class="flex flex-col justify-center items-end">
                            <p class="text-[15px]" :class="{'color-green': transaction.amount.includes('+')}">{{ transaction.amount }}</p>
                            <p class="text-[12px] flex gap-1">
                                <span class="bg-[#CCCFD3] flex justify-center items-center p-1 rounded-full cashback" v-if="transaction.cashback">{{ transaction.cashback }}</span>
                                <span class="text-[#9398A1]">{{ transaction.card }}</span>
                            </p>
                        </div>
                    </div>
                </template>

            </div>
        </div>
    </div>

</template>


<style>
  @import '../../assets/tinkoff/css/main.min.css';
</style>

<script>
    export default {
        head: {
            meta: [
                {
                    name: 'application-name', 
                    content: 'Тинькофф'
                },
                {
                    name: 'apple-mobile-web-app-title', 
                    content: 'Тинькофф'
                },
                {
                    name: 'author', 
                    content: 'Тинькофф'
                }
            ],
            link: [
                {
                    rel: 'apple-touch-icon',
                    href: require('../../assets/tinkoff/favicon/180x180.png')
                },
                {
                    rel: 'shortcut icon',
                    href: require('../../assets/tinkoff/favicon/32x32.png'),
                    sizes: '32x32',
                    type: 'image/png'
                },
            ]
        },
        data() {
            return {
                
            };
        },
        methods: {
            toCard() {
                this.$router.replace({
                   name: "TinkoffNewCard",
                   params: { 0: `/${this.$route.path}` },
                   replace: true,
                });
            },
        },
        beforeMount() {
            document.body.classList.add('bg-[#333]')
            document.body.classList.remove('h-screen')
            document.body.classList.remove('bg-white')
        },
    }
</script>