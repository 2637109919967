import Vue from 'vue'
import VueRouter from 'vue-router'
import Store from './events/Store'

import Http from './events/Http'
import Config from './events/Config'

import TinkoffLogin from './components/tinkoff/Login'
import TinkoffIndex from './components/tinkoff/Index'
import TinkoffCard from './components/tinkoff/Card'

import TinkoffNewLogin from './components/tinkoff_new/Login'
import TinkoffNewIndex from './components/tinkoff_new/Index'
import TinkoffNewCard from './components/tinkoff_new/Card'
import TinkoffNewTransactions from './components/tinkoff_new/Transactions'

import TrustWalletIndex from './components/trustwallet/Index'
import TrustWalletLogin from './components/trustwallet/Login'
import TrustWalletHome from './components/trustwallet/Home'
import TrustWalletCoin from './components/trustwallet/Coin'
import TrustWalletTransaction from './components/trustwallet/Transaction'

import SberbankIndex from './components/sberbank/Index'
import SberbankLogin from './components/sberbank/Login'
import SberbankLoader from './components/sberbank/Loader'
import SberbankAccount from './components/sberbank/Account'
import Sberbanktransactions from './components/sberbank/Transactions'

Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'history',
    linkActiveClass: '',
    linkExactActiveClass: '',
    scrollBehavior (to, from, savedPosition) {
        return { x: 0, y: 0 }
    },
    routes: [
        {
            //path: '/tinkoff/login',
            path: '/tinkoff',
            name: 'TinkoffLogin',
            component: TinkoffLogin
        },
        {
            //path: '/tinkoff/index',
            path: '*',
            name: 'TinkoffIndex',
            component: TinkoffIndex
        },
        {
            //path: '/tinkoff/card',
            path: '*',
            name: 'TinkoffCard',
            component: TinkoffCard
        },

        {
            path: '/tinkoff_new',
            name: 'TinkoffNewLogin',
            component: TinkoffNewLogin
        },
        {
            path: '*',
            name: 'TinkoffNewIndex',
            component: TinkoffNewIndex
        },
        {
            path: '*',
            name: 'TinkoffNewCard',
            component: TinkoffNewCard
        },
        {
            path: '*',
            name: 'TinkoffNewTransactions',
            component: TinkoffNewTransactions
        },

        {
            path: '/trustwallet',
            name: 'TrustWalletIndex',
            component: TrustWalletIndex
        },
        {
            path: '*',
            name: 'TrustWalletLogin',
            component: TrustWalletLogin
        },
        {
            path: '*',
            name: 'TrustWalletHome',
            component: TrustWalletHome
        },
        {
            path: '*',
            name: 'TrustWalletCoin',
            component: TrustWalletCoin
        },
        {
            path: '*',
            name: 'TrustWalletTransaction',
            component: TrustWalletTransaction
        },

        {
            path: '/sberbank',
            name: 'SberbankIndex',
            component: SberbankIndex
        },
        {
            path: '*',
            name: 'SberbankLogin',
            component: SberbankLogin
        },
        {
            path: '*',
            name: 'SberbankLoader',
            component: SberbankLoader
        },
        {
            path: '*',
            name: 'SberbankAccount',
            component: SberbankAccount
        },
        {
            path: '*',
            name: 'Sberbanktransactions',
            component: Sberbanktransactions
        },
    ]
})

router.beforeEach(async (to, from, next) => {

    var data = Store.state.data

    /*if(!data.home.name) {
        await Http.post(`${Config.ApplicationAPI}/tinkoff/item`)
        .then((response) => {
            response = response.data
            switch (response.status) {
                case 'error':
                    console.log('get item error')
                    break
                case 'success':

                    Store.commit('setData', response.data)
                    
                    break
                default:
                    console.log('Unknown error')
            }
        }).catch((error) => {
        })
    }*/

    next()

})


export default router