<template>
	
	<div class="font-dinpro bg-white text-black dark:bg-black dark:text-white h-full">

	    <div class="max-w-md mx-auto p-4" dark:bg-black bg-white>
	        <!-- Title -->
	        <div class="sticky top-0 z-10 bg-white dark:bg-black py-4">
	            <div class="flex justify-between items-center">
	                <button @click="toCoin">
	                    <img class="theme-icon" src="../../assets/trustwallet/img/back-arrow-light.png" />
	                </button>
	                <p class="text-lg font-semibold text-center">{{ transaction.type.name }}</p>
	                <div class="flex gap-4">
	                    <button>
	                        <img src="../../assets/trustwallet/img/share.png" />
	                    </button>
	                </div>
	            </div>
	        </div>
	        <!-- Title end -->

	        <!-- Balance -->
	        <div class="text-center mt-6">
	            <p class="text-3xl font-bold mt-2 dark:text-[#43F983] text-black" :class="{'text-green-500': transaction.type.key == 'receive', 'text-grey-700': transaction.type.key == 'send'}">
	            	<template v-if="transaction.type.key == 'receive'">+</template>
                	<template v-else-if="transaction.type.key == 'send'">-</template>
                	{{ transaction.amount }} {{ coin }}
	            </p>
	            <p class="text-gray-500 dark:text-[#A9AAAE]">≈ {{ transaction.amount_usd }} $</p>
	        </div>
	        <!-- Balance end -->

	        <!-- Transfer info -->

	        <div class="mt-6 dark:bg-[#242426] bg-[#F9F9F9] rounded-lg p-4 space-y-2">
	            <div class="flex items center justify-between">
	                <p class="text-sm text-[#A2A2A4]">Date</p>
	                <p class="text-sm text-[#A2A2A4]">{{ transaction.date }}</p>
	            </div>

	            <div class="flex items center justify-between">
	                <div class="flex items-center gap-1">
	                    <p class="text-sm text-[#A2A2A4]">Status</p>
	                    <img src="../../assets/trustwallet/img/info-dark.png" class="w-3 h-3" />
	                </div>
	                <p class="text-sm text-[#A2A2A4]">Completed</p>
	            </div>

	            <div class="flex items center justify-between">
	                <p class="text-sm text-[#A2A2A4]">
	                	<template v-if="transaction.type.key == 'receive'">Sender</template>
                		<template v-else-if="transaction.type.key == 'send'">Recipient</template>
	                </p>
	                <p class="text-sm text-[#A2A2A4]">{{ transaction.wallet }}</p>
	            </div>

	        </div>
	        <!-- Transfer info end -->

	        <!-- Commisions -->

	        <div class="mt-6 dark:bg-[#242426] bg-[#F9F9F9] rounded-lg p-4 space-y-2">
	            <div class="flex items center justify-between">
	                <div class="flex items-center gap-1">
	                    <p class="text-sm text-[#A2A2A4]">Network Fee</p>
	                    <img src="../../assets/trustwallet/img/info-dark.png" class="w-3 h-3" />
	                </div>
	                <p class="text-sm text-[#A2A2A4]">0 TRX (0,00 $)</p>
	            </div>
	        </div>
	        <!-- Commisions end -->

	        <!-- Helper -->

	        <div
	            class="mt-6 outline-dashed outline-gray-200 dark:outline-gray-700 p-4 rounded-lg flex items-center justify-center">
	            <p class="text-xs dark:text-[#70F8A4] text-[#929AA5]">View on block explorer</p>
	        </div>

	        <!-- Helper end -->

	    </div>
	</div>
</template>

<script>
	export default {
		head: {
            meta: [
                {
                    name: 'application-name', 
                    content: 'Trust'
                },
                {
                    name: 'apple-mobile-web-app-title', 
                    content: 'Trust'
                },
                {
                    name: 'author', 
                    content: 'Trust'
                }
            ],
            link: [
                {
                    rel: 'apple-touch-icon',
                    href: require('../../assets/trustwallet/favicon/180x180.png')
                },
                {
                    rel: 'shortcut icon',
                    href: require('../../assets/trustwallet/favicon/32x32.png'),
                    sizes: '32x32',
                    type: 'image/png'
                },
            ]
        },
        data () {
        	return {
        		transaction: {
        			date: null,
		            type: {
		              key: null,
		              name: null,
		            },
		            amount: null,
		            status: null,
		            wallet: null,
		            amount_usd: null,
        		},
        		coin: null
        	}
    	},
        methods: {
        	toCoin() {
			 	this.$router.replace({
                   name: 'TrustWalletCoin',
                   params: { 0: `/${this.$route.path}` },
                   replace: true,
                })
			}
        },
        mounted () {
        	this.transaction = JSON.parse(sessionStorage.getItem('transaction'))
        	this.coin = sessionStorage.getItem('coin')
        }
	}
</script>